import { Component, Input } from '@angular/core';
import { ClickInfo, InvoliGoogleMapComponent } from '../involi-google-map/involi-google-map.component';

@Component({
    selector: 'geojson-info-layer',
    templateUrl: 'geojson-info-layer.component.html',
    styleUrls: ['geojson-info-layer.component.scss']
})
export class GeojsonInfoLayerComponent
{
    @Input()
    set clickedFeature(value: ClickInfo | undefined)
    {
        this.clicked = value;
        this.onClick();
    }

    private clicked?: ClickInfo;
    private infoWindow = new google.maps.InfoWindow();

    constructor(private map: InvoliGoogleMapComponent)
    {

    }

    private onClick()
    {
        if(!this.clicked)
            return;

        let f = this.clicked.object.properties;
        let text: string = '';
        if(f['NAME_E'])
        {
            text =
                '<p>' +
                    '<b>Designation</b><br />' +
                    f['NAME_E'] +
                '</p>' +
                '<p>' +
                    '<b>Restriction</b><br />' +
                    f['RESTR_E'] +
                '</p>' +
                '<p>' +
                    '<b>Granting authority</b><br />' +
                    f['APPRO_E'] +
                '</p>' +
                '<p>' +
                    '<b>Further information</b><br />' +
                    `<a target="_blank" href="${f['LINK_E']}">${f['LINK_E']}</a>` +
                '</p>';
        }
        else if(f['name'])
        {
            text = `<p>${f['name']}</p>`;
        }

        if(text && this.clicked.latitude && this.clicked.longitude)
        {
            this.infoWindow.setContent(text);
            this.infoWindow.setPosition({lat: this.clicked.latitude, lng: this.clicked.longitude});
            this.infoWindow.open(this.map.googleMap);
        }
    }
}