import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig, API_CONFIG } from '../api';
import { ApiClient } from '../core/api-client';
import { ApiStatusService } from '../core';
import { GetDataDto, ReceiverDataDto, TrafficDataDto } from '@involi/api-shared';

@Injectable({
    providedIn: 'root'
})
export class DataApiService extends ApiClient
{
    constructor(@Inject(API_CONFIG) private apiConfig: ApiConfig,
                http: HttpClient,
                apiStatusService: ApiStatusService)
    {
        super(apiStatusService, http, 'Data');
    }

    getTrafficData(request: GetDataDto): Observable<TrafficDataDto>
    {
        return this.post<TrafficDataDto>(`${this.apiConfig.involiApiUrl}/data/traffic`, request);
    }

    getReceiverData(): Observable<ReceiverDataDto>
    {
        return this.get<ReceiverDataDto>(`${this.apiConfig.involiApiUrl}/data/receiver`);
    }
}
