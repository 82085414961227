import { IsBoolean, IsLatitude, IsLongitude, IsNumber, IsOptional, ValidateNested } from 'class-validator';

export class LatLngPoint
{
    @IsLatitude()
    latitude!: number;

    @IsLongitude()
    longitude!: number;
}

export class TrafficBox
{
    @IsLatitude()
    southLatitude!: number;

    @IsLatitude()
    northLatitude!: number;

    @IsLongitude()
    westLongitude!: number;

    @IsLongitude()
    eastLongitude!: number;

    @IsOptional()
    @IsBoolean()
    isNotStrict?: boolean;
}
