import { Injectable, NgZone } from '@angular/core';
import { SelectableDataController } from '../data/data-controller';
import { PredefinedIcon, PredefinedIconVariation, ReceiverDataDto, ReceiverDetail } from '@involi/api-shared';
import { DataProvider } from '../data/data-provider';
import { ReceiverEntry, ReceiverSubEntry } from './receiver.entry';
import { MapIconService } from '../map';
import { ReceiverRenderer } from './receiver.renderer';
import { DataOverlay } from '../data';
import { ReceiverFilter } from './receiver.filter';

@Injectable()
export class ReceiversService
{
    private dataController: SelectableDataController<ReceiverEntry, ReceiverSubEntry>;
    private renderer: ReceiverRenderer;
    private iconSize = 27;

    constructor(private dataProvider: DataProvider,
                iconService: MapIconService,
                receiverFilter: ReceiverFilter,
                zone: NgZone)
    {
        this.dataController = new SelectableDataController({
            collection: this.dataProvider.getDataCollection(),
            key: 'receiver',
            defaultSubEntry: () => new ReceiverSubEntry(),
            entryDerivator: (entry: ReceiverEntry, metadata?: any) => {
                entry.receiver.icon = iconService.getPredefinedIcon(
                    entry.receiver.item.is_online ? PredefinedIcon.InvoliMct : PredefinedIcon.InvoliMctOffline,
                    entry.selected ? PredefinedIconVariation.Selected : (entry.receiver.item.is_online ? PredefinedIconVariation.Online : PredefinedIconVariation.Offline)
                );
                entry.receiver.icon.size = this.iconSize;
            },
            selectionController: this.dataProvider.getSelectionController(),
            filter: receiverFilter
        });

        this.renderer = new ReceiverRenderer('receiver', {
            fontSize: 12,
            borderColor: [222, 227, 236, 255],
            borderWidth: 1.5,
            textColor: [0, 0, 0, 255]
        },
        {
            fontSize: 12,
            borderColor: [85, 83, 218, 255],
            borderWidth: 1.5,
            textColor: [0, 0, 0, 255]
        }, zone);
    }

    init(dataOverlay: DataOverlay)
    {
        this.dataController.attachOverlay(dataOverlay, this.renderer);

        const data$ = this.dataProvider.watchReceivers((data: ReceiverDataDto) => {
            this.dataController.getCollectionLayer().resetEntries('item', (data.receivers ?? []).map((r: ReceiverDetail) => ({ ...r, id: r.primary_id })));
        });

        this.dataController.attachSource(data$);
    }

    getDataController(): SelectableDataController<ReceiverEntry, ReceiverSubEntry>
    {
        return this.dataController;
    }
}