import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DataCollectionEntity, GeoObjectGroup, GetTrafficFromCollectionsDto, Record, RecordFormat, SourceInfo, TrafficBox } from '@involi/api-shared';
import { ApiConfig, API_CONFIG } from '../api';
import { Stream } from '../streams';
import { ApiClient } from '../core/api-client';
import { ApiStatusService } from '../core';

@Injectable({
    providedIn: 'root'
})
export class TrafficApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) apiConfig: ApiConfig, http: HttpClient, apiStatus: ApiStatusService)
    {
        super(apiStatus, http, 'Traffic');
        this.apiUrl = `${apiConfig.involiApiUrl}/traffic`;
    }

    getTrafficFromCollections(collectionIds: string[], boundingBox?: TrafficBox, format?: RecordFormat.Plain): Observable<Record[]>;
    getTrafficFromCollections(collectionIds: string[], boundingBox?: TrafficBox, format?: RecordFormat.Compressed): Observable<GeoObjectGroup[]>;
    getTrafficFromCollections(collectionIds: string[], boundingBox?: TrafficBox, format?: RecordFormat): Observable<(Record | GeoObjectGroup)[]>
    {
        const request: GetTrafficFromCollectionsDto = { collectionIds, boundingBox, format };
        return this.post<(Record | GeoObjectGroup)[]>(`${this.apiUrl}/collections`, request);
    }

    getSources(): Observable<SourceInfo[]>
    {
        return this.get<SourceInfo[]>(`${this.apiUrl}/sources`);
    }

    getSourcesWithMetadata(): Observable<SourceInfo[]>
    {
        return this.get<SourceInfo[]>(`${this.apiUrl}/sources/metadata`);
    }

    getTrafficCollections(): Observable<DataCollectionEntity[]>
    {
        return this.get<DataCollectionEntity[]>(`${this.apiUrl}/data-collection/traffic`);
    }
}
