import { GeoJsonLayer } from '@deck.gl/layers';
import { DataOverlay, LayerLevel } from '../data/data-overlay';
import { MapShapeStyle, hexToRGBA } from '../map/features/map-features';
import { FeatureCollection } from 'geojson';
import { PickingInfo } from '@deck.gl/core';
import { Observable, Subject } from 'rxjs';
import { NgZone } from '@angular/core';
import { ClickInfo } from '../map';

export type GeojsonData = string | FeatureCollection;

export class GeojsonRenderer
{
    private geojsonLayerId: string;

    private lastDataOverlay?: DataOverlay;
    private lastData?: GeojsonData;

    private defaultMapShapeStyle: MapShapeStyle = {
        fillColor: [0, 0, 0, 128],
        strokeColor: [255, 255, 255, 128],
        strokeWidth: 1,
        highlightStrokeWidth: 3,
        highlightStrokeColor: [255, 255, 255, 255]
    };

    private clickedFeature = new Subject<ClickInfo>();
    clickedFeature$: Observable<ClickInfo> = this.clickedFeature.asObservable();

    constructor(id: string, private zone: NgZone)
    {
        this.geojsonLayerId = `${id}-geojson`;
    }

    setData(geoJson: GeojsonData)
    {
        this.lastData = geoJson;
    }

    setOverlay(overlay: DataOverlay)
    {
        this.lastDataOverlay = overlay;
    }

    render(dataOverlay: DataOverlay, geoJson: GeojsonData)
    {
        this.lastDataOverlay = dataOverlay;
        this.lastData = geoJson;

        const geojsonLayer = new GeoJsonLayer({
            id: this.geojsonLayerId,
            data: typeof geoJson == 'string' ? geoJson : geoJson.features,
            getFillColor: (obj) => hexToRGBA(obj.properties?.color ?? obj.properties?.fill ?? '#ffffff', 128),
            pickable: true,
            stroked: true,
            lineWidthUnits: 'pixels',
            getLineWidth: this.defaultMapShapeStyle.strokeWidth ?? 1,
            getLineColor: this.defaultMapShapeStyle.strokeColor ?? [255, 255, 255, 128],
            onHover: (info) => {
                dataOverlay.getMap().setOptions({ draggableCursor: info.picked ? 'pointer' : 'grab' });
            },
            onClick: ((info: PickingInfo) => {
                if(!info.coordinate)
                    return;
                const clickInfo: ClickInfo = {
                    latitude: info.coordinate[1],
                    longitude: info.coordinate[0],
                    object: info.object
                };
                this.zone.run(() => this.clickedFeature.next(clickInfo));
                return true;
            })
        });

        dataOverlay.setLayer(geojsonLayer, LayerLevel.Background);
    }

    rerender()
    {
        if(this.lastDataOverlay && this.lastData)
            this.render(this.lastDataOverlay, this.lastData);
    }

    clear()
    {
        this.lastDataOverlay?.removeLayer(this.geojsonLayerId);
    }
}