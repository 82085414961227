import { DataCollectionEntity } from '../data-collection/data-collection.entity.js';
import { OrganizationEntity } from './organization.entity.js';
import { ReceiverEntity } from '../receiver/receiver.entity.js';
import { UserEntity } from './user.entity.js';
import { TrackerEntity } from '../tracker/tracker.entity.js';
import { ClientEntity } from './client.entity.js';
import { SubjectEntity } from './subject.entity.js';
import { ClientStreamEntity } from '../client-stream/client-stream.entity.js';
import { AlertEntity } from './alert.entity.js';
import { DataRecordingEntity } from '../affix-agent/data-recording.entity.js';
import { SettingsEntity } from './settings.entity.js';
import { DataStreamEntity } from '../data-stream/data-stream.entity.js';
import { RootEntity } from './root.entity.js';
import { GroupEntity } from './group.entity.js';

export enum EntityKind
{
    User = 'user',
    Client = 'client',
    Subject = 'subject',
    Organization = 'organization',
    Group = 'group',
    Receiver = 'receiver',
    DataCollection = 'data_collection',
    Tracker = 'tracker',
    ClientStream = 'client_stream',
    Alert = 'alert',
    DataRecording = 'data_recording',
    Settings = 'settings',
    DataStream = 'data_stream',
    Root = 'root'
}

export const entityKindList: string[] = Object
    .values(EntityKind)
    .filter((kind: any) => isNaN(Number(kind)));

export interface EntityKindTypeMap
{
    [EntityKind.User]: UserEntity;
    [EntityKind.Client]: ClientEntity;
    [EntityKind.Subject]: SubjectEntity;
    [EntityKind.Organization]: OrganizationEntity;
    [EntityKind.Group]: GroupEntity;
    [EntityKind.Receiver]: ReceiverEntity;
    [EntityKind.DataCollection]: DataCollectionEntity;
    [EntityKind.Tracker]: TrackerEntity;
    [EntityKind.ClientStream]: ClientStreamEntity;
    [EntityKind.Alert]: AlertEntity;
    [EntityKind.DataRecording]: DataRecordingEntity;
    [EntityKind.Settings]: SettingsEntity;
    [EntityKind.DataStream]: DataStreamEntity;
    [EntityKind.Root]: RootEntity;
};

export const EntityKindConstructorMap = {
    [EntityKind.User]: UserEntity,
    [EntityKind.Client]: ClientEntity,
    [EntityKind.Subject]: SubjectEntity,
    [EntityKind.Organization]: OrganizationEntity,
    [EntityKind.Group]: GroupEntity,
    [EntityKind.Receiver]: ReceiverEntity,
    [EntityKind.DataCollection]: DataCollectionEntity,
    [EntityKind.Tracker]: TrackerEntity,
    [EntityKind.ClientStream]: ClientStreamEntity,
    [EntityKind.Alert]: AlertEntity,
    [EntityKind.DataRecording]: DataRecordingEntity,
    [EntityKind.Settings]: SettingsEntity,
    [EntityKind.DataStream]: DataStreamEntity,
    [EntityKind.Root]: RootEntity
};

export type EntityType<K extends keyof EntityKindTypeMap> = EntityKindTypeMap[K];

export function entityConstructor<K extends keyof EntityKindTypeMap>(kind: K)
{
    return EntityKindConstructorMap[kind];
}

export function newEntity<K extends keyof EntityKindTypeMap>(kind: K, value: Partial<EntityType<K>>): EntityType<K>
{
    const entity: EntityType<K> = new EntityKindConstructorMap[kind](value) as EntityType<K>;
    for(const k in value)
    {
        if(!(k in entity) || entity[k] === undefined)
            entity[k] = value[k] as EntityType<K>[Extract<keyof EntityType<K>, string>];
    }
    return entity;
}

export const ENTITY_LABEL_DEFAULT: string = 'default';