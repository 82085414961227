<mat-toolbar color="primary">
    <span class="header-title" routerLink="/">Involi Admin Platform</span>
    <div class="spacer"></div>
    <div *ngIf="activeRequests$ | async as activeRequests" class="active-requests">
        <mat-progress-spinner mode="indeterminate" color="accent" diameter="40"></mat-progress-spinner>
        <span>{{ activeRequests }}</span>
    </div>
    <div [ngSwitch]="apiStatus$ | async" class="api-status">
        <div *ngSwitchCase="true">API OK</div>
        <div *ngSwitchCase="false" class="ko">API {{ koApis }} KO</div>
    </div>
    <user-header></user-header>
    <button mat-icon-button (click)="copyToken()" matTooltip="Copy current oauth token"><mat-icon>key</mat-icon></button>
</mat-toolbar>

<side-nav></side-nav>

<div class="content">
    <router-outlet></router-outlet>
</div>