import { NgModule } from '@angular/core';
import { TrackersService } from './trackers.service';
import { TrackerFilter } from './tracker.filter';

@NgModule({
    providers: [
        TrackersService,
        TrackerFilter
    ]
})
export class TrackersModule {}