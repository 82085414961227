import { Record } from '@involi/api-shared';
import { Selectable } from '../data/selection-controller';

export class RecordSubEntry
{
    item!: Record;
}

export interface RecordEntry extends Selectable
{
    record: RecordSubEntry;
}