import { IsEnum, IsNumber, IsString } from 'class-validator';

export enum OperationalStatus
{
    NotApplicable = 'NotApplicable',
    Test = 'Test',
    Production = 'Production'
}

export enum ComponentType
{
    Aerobits = 'TIM_MC1',
    GNS = 'GNS_5894'
}

export function componentTypeLabel(type: ComponentType)
{
    switch(type)
    {
    case ComponentType.Aerobits: return 'Aerobits';
    case ComponentType.GNS: return 'GNS';
    }
}

export type RssiThresholds = { [componentType: string]: number };
export interface ReceiverMetadata
{
    operational_status: OperationalStatus | null;
    rssi_thresholds: RssiThresholds;
}

export class SetOperationalStatusDto
{
    @IsEnum(OperationalStatus)
    operational_status!: OperationalStatus;
}

export class SetRssiThresholdDto
{
    @IsString()
    component_id!: string;

    @IsNumber()
    value!: number;
}

export class ClearRssiThresholdDto
{
    @IsString()
    component_id!: string;
}