import { AfterViewInit, Directive, Input, OnDestroy } from '@angular/core';
import { InvoliGoogleMapComponent } from '../google-maps/involi-google-map/involi-google-map.component';
import { MapManagerService } from './map-manager.service';

@Directive({
    selector: '[manageMap]'
})
export class ManageMapDirective implements AfterViewInit, OnDestroy
{
    @Input() mapGroup!: string;

    constructor(private map: InvoliGoogleMapComponent,
                private mapManager: MapManagerService)
    {

    }

    ngAfterViewInit()
    {
        if(this.map.googleMap)
            this.mapManager.registerMap(this.map.googleMap, this.mapGroup);
    }

    ngOnDestroy()
    {
        if(this.map.googleMap)
            this.mapManager.unregisterMap(this.map.googleMap, this.mapGroup);
    }
}