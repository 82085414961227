import { Observable, Subject, Subscription, combineLatest, map, share, startWith, tap } from "rxjs";
import { DataFilter } from "../data";
import { DestroyRef, Injectable } from "@angular/core";
import { TrackerEntry } from "./tracker.entry";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Injectable()
export class TrackerFilter extends DataFilter<TrackerEntry>
{
    private refresh$ = new Subject<void>();
    private changes$ = new Subject<Iterable<TrackerEntry>>();
    private searchString?: string;
    private statuses?: boolean[];
    private models?: string[];
    private subscription?: Subscription;

    constructor(private destroyRef: DestroyRef)
    {
        super();
    }

    consume(data: Observable<Iterable<TrackerEntry>>)
    {
        if(this.subscription)
            this.subscription.unsubscribe();

        this.subscription = combineLatest([data, this.refresh$.pipe(startWith(null))]).pipe(
            takeUntilDestroyed(this.destroyRef),
            map(([newEntries, _]) => newEntries),
            tap((newEntries: Iterable<TrackerEntry>) => {
                this.entries.clear();
                for(let entry of newEntries)
                {
                    if(this.filter(entry))
                        this.entries.set(entry.id, entry);
                }
            }),
            share()
        ).subscribe(this.changes$);
    }

    setSearchFilter(searchString?: string)
    {
        this.searchString = searchString;
        this.refresh$.next();
    }

    setStatusFilter(statuses?: boolean[])
    {
        this.statuses = statuses;
        this.refresh$.next();
    }

    setModelFilter(models?: string[])
    {
        this.models = models;
        this.refresh$.next();
    }

    private filter(entry: TrackerEntry): boolean
    {
        if(this.searchString)
        {
            let lowercaseValue = this.searchString.toLowerCase();
            if(!entry.tracker.item.meta?.label?.toLowerCase().includes(lowercaseValue)
                && !entry.tracker.item.entity?.meta?.custom_name?.toLowerCase().includes(lowercaseValue)
                && !entry.tracker.item.entity?.serialNumber?.toLowerCase().includes(lowercaseValue))
            {
                return false;
            }
        }

        if(this.statuses != undefined)
        {
            if(!this.statuses.includes(entry.tracker.item.device_status?.is_online ?? false))
                return false;
        }

        if(this.models != undefined)
        {
            if(!entry.tracker.item.entity?.model || !this.models.includes(entry.tracker.item.entity.model))
                return false;
        }

        return true;
    }

    watch(): Observable<Iterable<TrackerEntry>>
    {
        return this.changes$.asObservable();
    }
}