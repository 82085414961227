import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, filter, takeUntil } from 'rxjs';
import { Bounds, LatLng } from '../map';
import { setMapCenter, setMapZoom } from '../store/map.actions';
import { selectMapTypeId, selectMapZoom } from '../store/map.reducer';

export enum MapGroup
{
    Default = 'default',
    Main = 'main'
}

@Injectable()
export class MapManagerService
{
    private maps: { [group: string]: google.maps.Map[] } = {};
    private bounds$: Subject<Bounds[]> = new Subject<Bounds[]>();
    private mainBounds$ = new BehaviorSubject<Bounds>({ sw: { latitude: 0, longitude: 0 }, ne: { latitude: 0, longitude: 0 } });
    private destroyMap$ = new Subject<google.maps.Map>();

    constructor(private zone: NgZone,
                private store: Store)
    {

    }

    registerMap(googleMap: google.maps.Map, group: string)
    {
        if(!(group in this.maps))
            this.maps[group] = [];

        if(group == MapGroup.Main && this.maps[group].length >= 1)
            throw new Error('Trying to register a main map to MapManagerService while another main map has already been registered');

        this.maps[group].push(googleMap);

        googleMap.addListener('zoom_changed', () => {
            this.zone.run(() => {
                const zoom = googleMap.getZoom();
                if(zoom)
                    this.store.dispatch(setMapZoom({ zoom }));
            });
        });

        googleMap.addListener('bounds_changed', () => {
            this.zone.run(() => {
                this.bounds$.next(this.getCombinedBounds());
                this.mainBounds$.next(this.getMainBounds());
            });
        });

        googleMap.addListener('center_changed', () => {
            this.zone.run(() => {
                const mapCenter = googleMap.getCenter();
                if(mapCenter)
                    this.store.dispatch(setMapCenter({ center: { latitude: mapCenter.lat(), longitude: mapCenter.lng() }}));
            });
        });

        this.store.select(selectMapTypeId)
            .pipe(takeUntil(this.destroyMap$.pipe(filter(m => m == googleMap))))
            .subscribe((typeId : string) => googleMap.setMapTypeId(typeId));
    }

    unregisterMap(googleMap: google.maps.Map, group: string)
    {
        if(!(group in this.maps))
            return;
        this.maps[group] = this.maps[group].filter((map: google.maps.Map) => map != googleMap);
        google.maps.event.clearInstanceListeners(googleMap);
        this.destroyMap$.next(googleMap);
    }

    getMainBounds(): Bounds
    {
        const latlngBounds = this.maps[MapGroup.Main][0].getBounds();
        if(!latlngBounds)
        {
            return {
                sw: { latitude: -90, longitude: -180 },
                ne: { latitude: 90, longitude: 180 }
            };
        }
        return {
            sw: {
                latitude: latlngBounds.getSouthWest().lat(),
                longitude: latlngBounds.getSouthWest().lng(),
            },
            ne: {
                latitude: latlngBounds.getNorthEast().lat(),
                longitude: latlngBounds.getNorthEast().lng(),
            }
        };
    }

    getCombinedBounds(): Bounds[]
    {
        return Object.values(this.maps).reduce(
            (bounds: Bounds[], mapGroup: google.maps.Map[]) => bounds.concat(this.getGroupBounds(mapGroup)),
            []
        );
    }

    private getGroupBounds(maps: google.maps.Map[]): Bounds[]
    {
        return maps.filter((map: google.maps.Map) => map.getBounds()).map(
            (map: google.maps.Map) => {
                const latlngBounds = map.getBounds()!;
                return {
                    sw: {
                        latitude: latlngBounds.getSouthWest().lat(),
                        longitude: latlngBounds.getSouthWest().lng(),
                    },
                    ne: {
                        latitude: latlngBounds.getNorthEast().lat(),
                        longitude: latlngBounds.getNorthEast().lng(),
                    }
                };
            }
        );
    }

    getMinZoomLevel(): number
    {
        let min: number = Infinity;
        Object.values(this.maps).forEach((mapGroup: google.maps.Map[]) => {
            const levels: number[] = mapGroup
                .map((map: google.maps.Map) => map.getZoom())
                .filter((level: number | undefined): level is number => level != undefined);
            min = Math.min(...levels, min);
        });
        return min == Infinity ? 0 : min;
    }

    panTo(latitude: number, longitude: number)
    {
        const mainGroup = this.maps[MapGroup.Main];
        if(!mainGroup) return;
        mainGroup.forEach((map: google.maps.Map) => map.panTo({ lat: latitude, lng: longitude }));
    }

    panToIfNotVisible(latitude: number, longitude: number)
    {
        const mainGroup = this.maps[MapGroup.Main];
        if(!mainGroup) return;
        mainGroup.forEach((map: google.maps.Map) => {
            const bounds = map.getBounds();
            if(bounds && !bounds.contains({ lat: latitude, lng: longitude }))
                map.panTo({ lat: latitude, lng: longitude });
        });
    }

    panToPoints(points: LatLng[])
    {
        const mainGroup = this.maps[MapGroup.Main];
        if(!mainGroup) return;
        var bounds = new google.maps.LatLngBounds();

        for(const point of points)
            bounds.extend(new google.maps.LatLng(point.latitude, point.longitude));

        mainGroup.forEach((map: google.maps.Map) => map.fitBounds(bounds));
    }

    getZoomUpdates(): Observable<number>
    {
        return this.store.select(selectMapZoom);
    }

    getBoundsUpdates(): Observable<Bounds[]>
    {
        return this.bounds$.asObservable();
    }

    getMainBoundsUpdates(): Observable<Bounds>
    {
        return this.mainBounds$.asObservable();
    }
}
