import { NgModule } from '@angular/core';
import { DataModule } from '../data';
import { PermissionFlagDirective } from './permission-flag.directive';
import { PermissionsService } from './permissions.service';

@NgModule({
    declarations: [
        PermissionFlagDirective
    ],
    imports: [
        DataModule
    ],
    exports: [
        PermissionFlagDirective
    ],
    providers: [PermissionsService]
})
export class PermissionsModule {}