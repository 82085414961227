import { createAction, props } from '@ngrx/store';
import { LatLng, MapChart } from '../map';

export const setChartVisible = createAction('[Map] Set chart visibility', props<{ chartName: string, visible: boolean }>());
export const setAllChartVisible = createAction('[Map] Set all charts visibility', props<{ visible: boolean }>());
export const setCharts = createAction('[Map] Set map charts', props<{ charts: MapChart[] }>());
export const addCharts = createAction('[Map] Add map charts', props<{ charts: MapChart[] }>());
export const removeChart = createAction('[Map] Remove map chart', props<{ chartName: string }>());
export const setMapCenter = createAction('[Map] Set map center', props<{ center: LatLng }>());
export const setMapZoom = createAction('[Map] Set map zoom', props<{ zoom: number }>());
export const setMapTypeId = createAction('[Map] Set map type', props<{ typeId: string }>());
export const setSelectedRecordIds = createAction('[Map] Set selected record ids', props<{ selectedRecordIds: string[] }>());
export const addSelectedRecordId = createAction('[Map] Add selected record id', props<{ selectedRecordId: string }>());
export const removeSelectedRecordId = createAction('[Map] Remove selected record id', props<{ deselectedRecordId: string }>());
export const enableReceiversLayer = createAction('[Map] Enable receivers layer', props<{ enabled: boolean }>());
export const enableAlertsLayer = createAction('[Map] Enable alerts layer', props<{ enabled: boolean }>());