import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig, API_CONFIG } from '../api';
import { Observable, map } from 'rxjs';
import { GetSettingsDto, InvoliLiveSettingsEntity, InvoliLiveSettingsKey, SettingsEntity } from '@involi/api-shared';
import { ApiStatusService } from '../core/api-status.service';
import { ApiClient } from '../core/api-client';

@Injectable({
    providedIn: 'root'
})
export class SettingsApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                apiStatus: ApiStatusService,
                http: HttpClient)
    {
        super(apiStatus, http, 'Settings');
        this.apiUrl = `${config.involiApiUrl}/settings`;
    }

    getSettingsForEntity<E extends SettingsEntity = SettingsEntity>(entityType: string, entityId: string): Observable<E[]>
    {
        return this.get<E[]>(`${this.apiUrl}/for/${entityType}/${entityId}`, []);
    }

    addSettingsForEntity(configId: string, entityType: string, entityId: string)
    {
        return this.post(`${this.apiUrl}/for/${entityType}/${entityId}`, { configId: configId });
    }

    removeSettingsForEntity(configId: string, entityType: string, entityId: string)
    {
        return this.delete(`${this.apiUrl}/${configId}/for/${entityType}/${entityId}`);
    }

    getSubjectSettings<E extends SettingsEntity = SettingsEntity>(settingsKey: string): Observable<E | undefined>
    {
        return this.get<GetSettingsDto<E>>(`${this.apiUrl}/key/${settingsKey}`, {}).pipe(
            map((dto: GetSettingsDto<E>) => dto.settings)
        );
    }

    getDirectSubjectSettings<E extends SettingsEntity = SettingsEntity>(settingsKey: string): Observable<E | undefined>
    {
        return this.get<GetSettingsDto<E>>(`${this.apiUrl}/direct/key/${settingsKey}`).pipe(
            map((dto: GetSettingsDto<E>) => dto.settings)
        );
    }

    setDirectSubjectLiveSettings(settings: InvoliLiveSettingsEntity)
    {
        return this.post<InvoliLiveSettingsEntity>(`${this.apiUrl}/direct/key/${InvoliLiveSettingsKey}`, { settings });
    }

    getGroupSettings<E extends SettingsEntity = SettingsEntity>(entityType: string, entityId: string, settingsKey: string): Observable<E[]>
    {
        return this.get<E[]>(`${this.apiUrl}/for-groups/${entityType}/${entityId}/key/${settingsKey}`, []);
    }
}