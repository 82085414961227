import { IsArray, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { TrafficBox } from './traffic-box.dto.js';
import { Type } from 'class-transformer';

export class GetTrafficDto
{
    @IsOptional()
    @ValidateNested()
    @Type(() => TrafficBox)
    boundingBox?: TrafficBox;

    @IsOptional()
    @IsNumber()
    upperAltitude?: number;

    @IsOptional()
    @IsNumber()
    lowerAltitude?: number;

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    selectedTrafficSourceIds?: string[];
}

export class GetTrafficFromLayersDto extends GetTrafficDto
{
    @IsArray()
    layers!: string[];
}