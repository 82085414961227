import { IsISO31661Alpha2, IsOptional, IsPhoneNumber, IsPostalCode, IsString } from 'class-validator';

export class UpdateProfileDto
{
    @IsString()
    @IsOptional()
    first_name?: string;

    @IsString()
    @IsOptional()
    last_name?: string;

    @IsPhoneNumber()
    @IsOptional()
    phone_number?: string;

    @IsString()
    @IsOptional()
    uas_operator_id?: string;

    @IsString()
    @IsOptional()
    address1?: string;

    @IsString()
    @IsOptional()
    address2?: string;

    @IsString()
    @IsOptional()
    city?: string;

    @IsPostalCode('any')
    @IsOptional()
    zip_code?: string;

    @IsISO31661Alpha2()
    @IsOptional()
    country?: string;
}