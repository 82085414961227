import { IsArray, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class GetCellsDto
{
    @IsString()
    @IsNotEmpty()
    mapName!: string;

    @IsArray()
    @IsNotEmpty()
    @IsString({ each: true})
    @IsOptional()
    ids?: string[];

    @IsNumber()
    @IsOptional()
    ignoreMinAglAbove?: number;
}