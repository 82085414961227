import { getMetadataStorage, ValidationTypes } from 'class-validator';
import { entityConstructor, EntityKind } from './entity-kind.js';
import CryptoJS from 'crypto-js';
import { Entity } from './entity.js';
import { SettingsEntity } from './settings.entity.js';
import { settingsEntityConstructor } from '../settings/settings.js';
import { relationshipConstructor, RelationshipType as RelationshipType } from './relationship-type.js';

export const IS_OPTIONAL = 'involiIsOptional';

export function entityTypeConstraints(kind: EntityKind, instance?: Entity)
{
    const targetConstructor = instance && kind == EntityKind.Settings ?
        settingsEntityConstructor((instance as SettingsEntity).key) :
        entityConstructor(kind)
    const metadataStorage = getMetadataStorage();
    const targetMetadatas = metadataStorage.getTargetValidationMetadatas(targetConstructor, <any>undefined, false, false, undefined);
    const groupedMetadatas = metadataStorage.groupByPropertyName(targetMetadatas);
    return Object.fromEntries(Object.entries(groupedMetadatas).map(([property, decorators]) => {
        const CM = decorators.map(decorator => {
            const constraintMetadata = metadataStorage.getTargetValidatorConstraints(decorator.constraintCls);
            if(constraintMetadata.length)
                return metadataStorage.getTargetValidatorConstraints(decorator.constraintCls).map(v => v.name)
            if(decorator.type == ValidationTypes.CONDITIONAL_VALIDATION)
                return [IS_OPTIONAL];
            return [];
        });
        return [property, CM.flat()];
    }));
}

export function relationshipTypeConstraints(relationshipType: RelationshipType)
{
    const targetConstructor = relationshipConstructor(relationshipType);
    const metadataStorage = getMetadataStorage();
    const targetMetadatas = metadataStorage.getTargetValidationMetadatas(targetConstructor, <any>undefined, false, false, undefined);
    const groupedMetadatas = metadataStorage.groupByPropertyName(targetMetadatas);
    return Object.fromEntries(Object.entries(groupedMetadatas).map(([property, decorators]) => {
        const CM = decorators.map(decorator => {
            const constraintMetadata = metadataStorage.getTargetValidatorConstraints(decorator.constraintCls);
            if(constraintMetadata.length)
                return metadataStorage.getTargetValidatorConstraints(decorator.constraintCls).map(v => v.name)
            if(decorator.type == ValidationTypes.CONDITIONAL_VALIDATION)
                return [IS_OPTIONAL];
            return [];
        });
        return [property, CM.flat()];
    }));
}

export function makePrimaryId(streamName: string, streamPrimaryId: string): string
{
    return CryptoJS.SHA1(streamName + '|' + streamPrimaryId).toString(CryptoJS.enc.Hex);
}