import { IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { Entity } from '../entity/entity.js';

export class DataStreamEntity extends Entity
{
    @IsString()
    @IsNotEmpty()
    label!: string;

    @IsBoolean()
    @IsOptional()
    containsPositions?: boolean;
}