import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiConfig, API_CONFIG } from '../api';
import { AlertDefinition, AlertDefinitionDto, AlertStatus, WebsocketCloseCode, WebsocketStreamData } from '@involi/api-shared';

@Injectable()
export class AlertApiService implements OnDestroy
{
    private alertStatus$ = new Subject<AlertStatus[]>();
    private ws?: WebSocket;

    constructor(@Inject(API_CONFIG) private apiConfig: ApiConfig, private http: HttpClient)
    {
    }

    ngOnDestroy()
    {
        this.alertStatus$?.complete();
        this.ws?.close();
    }

    getUserAlerts(): Observable<AlertDefinition[]>
    {
        return this.http.get<AlertDefinition[]>(`${this.apiConfig.involiApiUrl}/alert`);
    }

    getAllAlerts(): Observable<AlertDefinition[]>
    {
        return this.http.get<AlertDefinition[]>(`${this.apiConfig.involiApiUrl}/alert/all`);
    }

    getAlert(alertId: string): Observable<AlertDefinition>
    {
        return this.http.get<AlertDefinition>(`${this.apiConfig.involiApiUrl}/alert/${alertId}`);
    }

    putAlert(alert: AlertDefinitionDto): Observable<void>
    {
        return this.http.put<void>(`${this.apiConfig.involiApiUrl}/alert`, alert);
    }

    deleteAlert(alertId: string): Observable<void>
    {
        return this.http.delete<void>(`${this.apiConfig.involiApiUrl}/alert/${alertId}`);
    }

    enableAlert(alertId: string, enabled: boolean): Observable<void>
    {
        return this.http.post<void>(`${this.apiConfig.involiApiUrl}/alert/${alertId}/enable`, { enabled: enabled });
    }

    private getStreamTicket(): Observable<{ ticket: string }>
    {
        return this.http.get<{ ticket: string }>(`${this.apiConfig.involiApiUrl}/alert/stream/ticket`);
    }

    private openWebsocket()
    {
        this.getStreamTicket().subscribe((response: { ticket: string }) => {
            this.ws = new WebSocket(`${this.apiConfig.involiApiUrl?.replace(/http(s?):\/\//, 'ws$1://')}/alert/stream?ticket=${response.ticket}`);
            this.ws.onmessage = (e: MessageEvent<string>) => {
                let data: WebsocketStreamData<AlertStatus[]> = JSON.parse(e.data);
                if(data.data)
                    this.alertStatus$?.next(data.data);
            };
            this.ws.onclose = (e: CloseEvent) => {
                if(e.code != WebsocketCloseCode.InvalidTicket)
                    setTimeout(() => this.openWebsocket(), 10000);
            }
        });
    }

    observeAlerts(): Observable<AlertStatus[]>
    {
        if(!this.ws || this.ws.readyState === WebSocket.CLOSED)
            this.openWebsocket();

        return this.alertStatus$?.asObservable();
    }
}
