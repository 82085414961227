import { InjectionToken } from '@angular/core';

export type RGBA = [number, number, number, number];

export function hexToRGBA(hex: string, opacity: number = 255): RGBA
{
    var regResult =  /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const rgb =  regResult ? {
        r: parseInt(regResult[1], 16),
        g: parseInt(regResult[2], 16),
        b: parseInt(regResult[3], 16)
    } : null;
    if(!rgb) return [255, 127, 15, opacity];
    return [rgb.r, rgb.g, rgb.b, opacity];
}

export function isSameColor(color1: RGBA, color2: RGBA): boolean
{
    return color1[0] == color2[0]
        && color1[1] == color2[1]
        && color1[2] == color2[2]
        && color1[3] == color2[3];
}

export interface MapLabelStyle
{
    textColor?: RGBA;
    fontSize?: number;
    fontWeight?: string;
    borderColor?: RGBA;
    borderWidth?: number;
}

export interface Point
{
    x: number;
    y: number;
}

export interface IconLoadingProperties
{
    url: string;
    anchorX: number;
    anchorY: number;
    width: number;
    height: number;
    mask: boolean;
    id?: string;
}

export interface IconProperties
{
    load: IconLoadingProperties;
    size: number;
    sizeScale: number;
    anchor: Point;
    origin: Point;
    labelOrigin: Point;
}

export interface MapIconColorStyle
{
    default?: RGBA;
    selected?: RGBA;
    offline?: RGBA;
    online?: RGBA;
    onGround?: RGBA;
    error?: RGBA;
}

export interface MapIconStyle
{
    color?: MapIconColorStyle;
    headingFix?: number;
    borderColor?: MapIconColorStyle;
}

export interface MapIcon
{
    properties: IconProperties;
    heading?: number;
    color?: RGBA;
    headingWithArrow?: boolean;
}

export interface MapShapeStyle
{
    fillColor?: RGBA;
    strokeColor?: RGBA;
    strokeWidth?: number; // pixels
    highlightStrokeWidth?: number; // pixels
    highlightStrokeColor?: RGBA;
}

export const DEFAULT_MAP_ICON_STYLE = new InjectionToken<MapIconStyle>('defaultMapIconStyle');