import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardComponent } from './card/card.component';
import { ClockComponent } from './clock/clock.component';
import { ExpansionPanelComponent, ExpansionPanelHeaderComponent } from './expansion-panel/expansion-panel.component';
import { InvoliconComponent } from './involicon/involicon.component';

@NgModule({
    declarations: [
        CardComponent,
        ExpansionPanelComponent,
        ExpansionPanelHeaderComponent,
        InvoliconComponent,
        ClockComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        CardComponent,
        ExpansionPanelComponent,
        ExpansionPanelHeaderComponent,
        InvoliconComponent,
        ClockComponent
    ]
})
export class UiModule {}