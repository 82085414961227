import { Component, Host } from '@angular/core';

@Component({
    selector: 'expansion-panel',
    templateUrl: 'expansion-panel.component.html',
    styleUrls: ['expansion-panel.component.scss']
})
export class ExpansionPanelComponent
{
    expanded: boolean = false;

    toggle()
    {
        this.expanded = !this.expanded;
    }
}

@Component({
    selector: 'expansion-panel-header',
    templateUrl: 'expansion-panel-header.component.html',
    styleUrls: ['expansion-panel-header.component.scss'],
    host: { '(click)': 'toggle()' }
})
export class ExpansionPanelHeaderComponent
{
    constructor(@Host() private panel: ExpansionPanelComponent)
    {

    }

    toggle()
    {
        this.panel.toggle();
    }

    isExpanded()
    {
        return this.panel.expanded;
    }
}