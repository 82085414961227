import { Inject, Injectable } from '@angular/core';
import { MapIconDataService } from './map-icon-data.service';
import { DEFAULT_MAP_ICON_STYLE, IconLoadingProperties, MapIcon, MapIconStyle, RGBA, hexToRGBA, isSameColor } from '../features/map-features';
import { PredefinedIcon, PredefinedIconVariation } from '@involi/api-shared';

export interface IconDefinition
{
    load: IconLoadingProperties;
    anchor: { x: number, y: number };
    labelOrigin: { x: number, y: number };
    headingWithArrow: boolean;
    fixedHeading: boolean;
    color: RGBA;
    size: number;
}

const DEFAULT_ICON_SCALE = 3;

function makePredefinedIcons(
    icon: string,
    base: IconDefinition,
    iconData: MapIconDataService,
    defaultColor: RGBA,
    selectedColor: RGBA,
    onGroundColor: RGBA,
    onlineColor: RGBA,
    offlineColor: RGBA,
    headingWithArrow: boolean = false,
    fixedHeading: boolean = false)
{
    const defaultIcon = iconData.getCachedIcon(icon, ...defaultColor, DEFAULT_ICON_SCALE);
    const selectedIcon = iconData.getCachedIcon(icon, ...selectedColor, DEFAULT_ICON_SCALE);
    const onGroundIcon = iconData.getCachedIcon(icon, ...onGroundColor, DEFAULT_ICON_SCALE);
    const onlineIcon = iconData.getCachedIcon(icon, ...onlineColor, DEFAULT_ICON_SCALE);
    return {
        [PredefinedIconVariation.Default]: { ...base, load: defaultIcon, headingWithArrow, fixedHeading },
        [PredefinedIconVariation.Selected]: { ...base, load: selectedIcon, color: selectedColor, headingWithArrow, fixedHeading },
        [PredefinedIconVariation.OnGround]: { ...base, load: onGroundIcon, color: onGroundColor, headingWithArrow, fixedHeading },
        [PredefinedIconVariation.Online]: { ...base, load: onlineIcon, color: onlineColor, headingWithArrow, fixedHeading },
        [PredefinedIconVariation.Offline]: { ...base, load: onGroundIcon, color: offlineColor, headingWithArrow, fixedHeading }
    };
}

function predefinedIconToString(icon: PredefinedIcon): string
{
    switch(icon)
    {
        case PredefinedIcon.LargePlane:
            return 'large_plane';
        case PredefinedIcon.SmallPlane:
            return 'small_plane';
        case PredefinedIcon.Balloon:
            return 'balloon';
        case PredefinedIcon.Drone:
            return 'drone';
        case PredefinedIcon.GroundObstacle:
            return 'ground_obstacle';
        case PredefinedIcon.GroundVehicle:
            return 'ground_vehicle';
        case PredefinedIcon.Helicopter:
            return 'helicopter';
        case PredefinedIcon.InvoliMct:
            return 'involi-mct';
        case PredefinedIcon.InvoliMctOffline:
            return 'involi-mct-offline';
        case PredefinedIcon.Parachute:
            return 'parachute';
        case PredefinedIcon.Paraglider:
            return 'paraglider';
        default:
            return 'unknown';
    }
}

@Injectable()
export class MapIconService
{
    private predefinedIcons: { [icon: string]: { [variation: string]: IconDefinition } };

    constructor(private iconData: MapIconDataService,
                @Inject(DEFAULT_MAP_ICON_STYLE) iconStyle: MapIconStyle)
    {
        const defaultColor: RGBA = iconStyle.color?.default ?? [254, 184, 46, 255];
        const selectedColor: RGBA = iconStyle.color?.selected ?? [53, 211, 255, 255];
        const onGroundColor: RGBA = iconStyle.color?.onGround ?? [135, 142, 162, 255];
        const onlineColor: RGBA = iconStyle.color?.online ?? [ 88, 201, 0, 255 ];
        const offlineColor: RGBA = iconStyle.color?.offline ?? [135, 142, 162, 255];
        const largePlaneIcon = this.iconData.getCachedIcon('large_plane', 254, 184, 46, 255, DEFAULT_ICON_SCALE);
        const base: IconDefinition = {
            load: largePlaneIcon,
            anchor: { x: largePlaneIcon.width/2, y: largePlaneIcon.height/2 },
            labelOrigin: { x: 0, y: 40*(largePlaneIcon.height - largePlaneIcon.anchorY)/largePlaneIcon.height + 7 },
            headingWithArrow: false,
            color: [254, 184, 46, 255],
            size: 40,
            fixedHeading: false
        };
        this.predefinedIcons = {
            [PredefinedIcon.LargePlane]: makePredefinedIcons(predefinedIconToString(PredefinedIcon.LargePlane), base, iconData, defaultColor, selectedColor, onGroundColor, onlineColor, offlineColor),
            [PredefinedIcon.SmallPlane]: makePredefinedIcons(predefinedIconToString(PredefinedIcon.SmallPlane), base, iconData, defaultColor, selectedColor, onGroundColor, onlineColor, offlineColor),
            [PredefinedIcon.Balloon]: makePredefinedIcons(predefinedIconToString(PredefinedIcon.Balloon), base, iconData, defaultColor, selectedColor, onGroundColor, onlineColor, offlineColor, true, true),
            [PredefinedIcon.Drone]: makePredefinedIcons(predefinedIconToString(PredefinedIcon.Drone), base, iconData, defaultColor, selectedColor, onGroundColor, onlineColor, offlineColor),
            [PredefinedIcon.GroundObstacle]: makePredefinedIcons(predefinedIconToString(PredefinedIcon.GroundObstacle), base, iconData, defaultColor, selectedColor, onGroundColor, onlineColor, offlineColor, false, true),
            [PredefinedIcon.GroundVehicle]: makePredefinedIcons(predefinedIconToString(PredefinedIcon.GroundVehicle), base, iconData, defaultColor, selectedColor, onGroundColor, onlineColor, offlineColor),
            [PredefinedIcon.Helicopter]: makePredefinedIcons(predefinedIconToString(PredefinedIcon.Helicopter), base, iconData, defaultColor, selectedColor, onGroundColor, onlineColor, offlineColor),
            [PredefinedIcon.InvoliMct]: makePredefinedIcons(predefinedIconToString(PredefinedIcon.InvoliMct), base, iconData, defaultColor, selectedColor, onGroundColor, onlineColor, offlineColor),
            [PredefinedIcon.InvoliMctOffline]: makePredefinedIcons(predefinedIconToString(PredefinedIcon.InvoliMctOffline), base, iconData, defaultColor, selectedColor, onGroundColor, onlineColor, offlineColor),
            [PredefinedIcon.Parachute]: makePredefinedIcons(predefinedIconToString(PredefinedIcon.Parachute), base, iconData, defaultColor, selectedColor, onGroundColor, onlineColor, offlineColor, true, true),
            [PredefinedIcon.Paraglider]: makePredefinedIcons(predefinedIconToString(PredefinedIcon.Paraglider), base, iconData, defaultColor, selectedColor, onGroundColor, onlineColor, offlineColor, true, true)
        };
    }

    getPredefinedIcon(icon: PredefinedIcon, variation: PredefinedIconVariation = PredefinedIconVariation.Default, defaultColorOverride?: string, iconGroup?: string): IconDefinition
    {
        if(variation != PredefinedIconVariation.Default || !defaultColorOverride)
            return this.predefinedIcons[icon][variation];

        const groupVariationString = iconGroup ?? defaultColorOverride;
        const defaultOverideRGBA = hexToRGBA(defaultColorOverride);

        // if this is the first icon for this aircraft type/stream, make it slightly different this time only
        // this is to work around the deck.gl bug that prevents changing back to the initial icon
        if(!this.predefinedIcons[icon][groupVariationString])
            defaultOverideRGBA[3] = defaultOverideRGBA[3] - 0.1;

        if(!this.predefinedIcons[icon][groupVariationString] || !isSameColor(this.predefinedIcons[icon][groupVariationString].color, defaultOverideRGBA))
        {
            this.predefinedIcons[icon][groupVariationString] = {
                ...this.predefinedIcons[icon][PredefinedIconVariation.Default],
                color: defaultOverideRGBA,
                load: this.iconData.getCachedIcon(predefinedIconToString(icon), ...defaultOverideRGBA, DEFAULT_ICON_SCALE, iconGroup)
            }
        }
        return this.predefinedIcons[icon][groupVariationString];
    }

    getIcon(
        tileSize: number,
        sizeScale: number = 1,
        iconName: string = 'drone',
        heading: number = 0,
        color: RGBA = [255, 127, 15, 1],
        renderScale?: number,
        headingWithArrow?: boolean): MapIcon
    {
        if(heading > 360) heading = heading % 360;
        while (heading < 0) heading += 360;

        if(!iconName) iconName = 'jet';

        const icon = this.iconData.getCachedIcon(iconName, color[0], color[1], color[2], color[3], renderScale ?? sizeScale);

        return {
            properties: {
                load: icon,
                size: tileSize,
                sizeScale: sizeScale,
                anchor: {
                    x: tileSize / 2, // horizontal center
                    y: tileSize / 2  // vertical center
                },
                origin: {
                    x: 0,
                    y: 0
                },
                labelOrigin: {
                    x: 0, // horizontal center
                    y: tileSize * (icon.height - icon.anchorY) / icon.height + 14 // vertical bottom + 14px
                }
            },
            heading: heading,
            color: color,
            headingWithArrow: headingWithArrow
        };
    }
}
