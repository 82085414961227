import { EntityMeta } from "../entity";

export enum DataCollectionWarning
{
    SimulatedData = 'SimulatedData'
}

export interface DataCollectionMetadata extends EntityMeta
{
    warnings?: DataCollectionWarning[];
}