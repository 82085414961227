export interface AffixNodeMetrics
{
    id: string;
    metrics: any;
}

export interface AffixChainMetrics
{
    id: string;
    nodes: AffixNodeMetrics[];
}

export interface AffixInstanceMetrics
{
    chains: AffixChainMetrics[];
}

export interface AffixRecordingMetrics
{
    received_records: number;
    translated_records: number;
    recorded_bytes: number;
    completed_files: number;
}