import { NgZone } from '@angular/core';
import { LayerDataSource, PickingInfo } from '@deck.gl/core';
import { IconLayer, TextLayer, TextLayerProps } from '@deck.gl/layers';
import { GeoObjectIndex } from '@involi/api-shared';
import { DataView } from '../data/data-view';
import { DataOverlay, LayerLevel } from '../data/data-overlay';
import { Renderer } from '../data/renderer';
import { SelectionController } from '../data/selection-controller';
import { MapLabelStyle } from '../map/features/map-features';
import { RoundedTextBackgroundLayer } from '../map/google-maps/rounded-text-background-layer/rounded-text-background.layer';
import { ZIndexedMultiIconLayer } from '../map/google-maps/z-indexed-multi-icon-layer/z-indexed-multi-icon.layer';
import { GeoObjectEntry } from './geo-object.entry';

export class GeoObjectRenderer implements Renderer<GeoObjectEntry>
{
    private selectionController?: SelectionController;
    private iconLayerId: string;
    private labelLayerId: string;
    private arrowLayerId: string;

    private lastDataOverlay?: DataOverlay;
    private lastData?: DataView<GeoObjectEntry>;
    private areLabelsRendered: boolean = false;
    private hoveredObjectId?: string;
    private selectedObjectIds: string[] = [];

    private characterSet: string[] = [];

    constructor(id: string,
                private labelStyle: MapLabelStyle,
                private selectedLabelStyle: MapLabelStyle,
                private zone: NgZone)
    {
        this.iconLayerId = `${id}-icons`;
        this.labelLayerId = `${id}-labels`;
        this.arrowLayerId = `${id}-arrows`;

        for(let i = 32; i < 128; i++)
            this.characterSet.push(String.fromCharCode(i));
        this.characterSet.push('ü');
        this.characterSet.push('▲');
        this.characterSet.push('▼');
    }

    attachSelectionController(selectionController?: SelectionController)
    {
        this.selectionController = selectionController;
        this.selectionController?.selectedIds$.subscribe((ids: Set<string>) => this.selectedObjectIds = [...ids]);
    }

    render(dataOverlay: DataOverlay, data: DataView<GeoObjectEntry>)
    {
        this.lastDataOverlay = dataOverlay;
        this.lastData = data;

        const iconSizeScale = Math.max(0.1, ((dataOverlay.getMap().getZoom() ?? 8) - 3) * 0.2);
        const iconLayer = new IconLayer({
            id: this.iconLayerId,
            data: [...data.getEntries()], // An array is needed for the picking engine to provide the object (otherwise it juste provides an index)
            getIcon: (d: GeoObjectEntry) => d.geoObject.icon.load,
            getSize: (d: GeoObjectEntry) => d.geoObject.icon.size*iconSizeScale,
            getPosition: (d: GeoObjectEntry) => [d.geoObject.item[GeoObjectIndex.Longitude], d.geoObject.item[GeoObjectIndex.Latitude]],
            getAngle: (d: GeoObjectEntry) => d.geoObject.icon.fixedHeading ? 0 : 360 - (d.geoObject.item[GeoObjectIndex.Heading] ?? 0),
            onHover: this.onHover.bind(this, dataOverlay.getMap()),
            alphaCutoff: 0,
            pickable: !!this.selectionController,
            onClick: ((info: PickingInfo) => {
                const item: GeoObjectEntry = info.object;
                this.zone.run(() => this.selectionController!.selectItem(item.geoObject.item[GeoObjectIndex.Id]));
                return true;
            }),
            // parameters to avoid blending artifacts ; works on chrome but does not on firefox
            // for firefox, what works is loadOptions.image.type = 'image', but this does not work on chrome
            // maybe use a canvas instead of passing svg directly
            // https://github.com/visgl/deck.gl/issues/2169
            // No longer needed ? Does not work with string constant as it is below (since deck.gl v9)
            // parameters: {
            //     blendFunc: ['one', 'one-minus-src-alpha']
            // }
        });

        this.areLabelsRendered = this.shouldRenderLabel(dataOverlay);
        let textLayerData: LayerDataSource<GeoObjectEntry> = [];
        if(this.areLabelsRendered)
            textLayerData = data.getEntries();
        else
        {
            const arrayData: GeoObjectEntry[] = [];
            if(this.hoveredObjectId)
            {
                const hoveredEntry = data.tryGet(this.hoveredObjectId);
                if(hoveredEntry)
                    arrayData.push(hoveredEntry);
            }

            for(let selectedId of this.selectedObjectIds)
            {
                const selectedEntry = data.tryGet(selectedId);
                if(selectedEntry)
                    arrayData.push(selectedEntry);
            }
            textLayerData = arrayData;
        }

        const textLayerOptions: TextLayerProps<GeoObjectEntry> = {
            id: this.labelLayerId,
            data: textLayerData,
            fontFamily: 'Inter',
            getText: (d: GeoObjectEntry) => d.geoObject.label,
            getPosition: (d: GeoObjectEntry, { index }) => [d.geoObject.item[GeoObjectIndex.Longitude], d.geoObject.item[GeoObjectIndex.Latitude], data.size() - index],
            getSize: (d: GeoObjectEntry) => d.selected ? this.selectedLabelStyle.fontSize! : this.labelStyle.fontSize!,
            getTextAnchor: 'middle',
            getAlignmentBaseline: 'center',
            getPixelOffset: (d: GeoObjectEntry) => [
                d.geoObject.icon.labelOrigin.x*iconSizeScale,
                d.geoObject.icon.labelOrigin.y*iconSizeScale
            ],
            background: true,
            backgroundPadding: [10, 3],
            getColor: (d: GeoObjectEntry) => d.selected ? this.selectedLabelStyle.textColor! : this.labelStyle.textColor!,
            getBorderColor: (d: GeoObjectEntry) => d.selected ? this.selectedLabelStyle.borderColor! : this.labelStyle.borderColor!,
            getBorderWidth: (d: GeoObjectEntry) => d.selected ? this.selectedLabelStyle.borderWidth! : this.labelStyle.borderWidth!,
            characterSet: this.characterSet
        };
        (<any>textLayerOptions)._subLayerProps = {
            background: { type: RoundedTextBackgroundLayer },
            characters: { type: ZIndexedMultiIconLayer }
        };
        const labelLayer = new TextLayer(textLayerOptions);

        const arrowLayer = new TextLayer({
            id: this.arrowLayerId,
            data: new GeoObjectWithArrowHeadingIterable(data.getEntries()),
            fontFamily: 'Inter',
            getText: (_: GeoObjectEntry) => '▲',
            getPosition: (d: GeoObjectEntry, { index }) => [d.geoObject.item[GeoObjectIndex.Longitude], d.geoObject.item[GeoObjectIndex.Latitude], data.size() - index],
            getSize: (d: GeoObjectEntry) => Math.max((d.geoObject.icon.size*iconSizeScale/8), 5),
            getTextAnchor: 'middle',
            getAlignmentBaseline: 'center',
            getPixelOffset: (d: GeoObjectEntry) => this.getArrowIconOffset(
                d.geoObject.item[GeoObjectIndex.Latitude],
                d.geoObject.item[GeoObjectIndex.Longitude],
                360 - (d.geoObject.item[GeoObjectIndex.Heading] ?? 0),
                d.geoObject.icon.size*iconSizeScale/3
            ),
            getColor: (d: GeoObjectEntry) => d.geoObject.icon.color ?? [125, 0, 150],
            getAngle: (d: GeoObjectEntry) => 360 - (d.geoObject.item[GeoObjectIndex.Heading] ?? 0),
            outlineColor: [16, 16, 16, 255],
            outlineWidth: 4,
            fontSettings: {
                sdf: true,
                radius: 20
            },
            characterSet: this.characterSet
        });

        dataOverlay.setLayers([iconLayer, labelLayer, arrowLayer], LayerLevel.Traffic);
    }

    private getArrowIconOffset(latitude: number, longitude: number, angle: number, distance: number): [number, number]
    {
        let radius: number = angle * Math.PI / 180;
        let x: number = longitude + (distance * Math.sin(radius));
        let y: number = latitude + (distance * Math.cos(radius));
        return [longitude - x, latitude - y];
    }

    private shouldRenderLabel(dataOverlay: DataOverlay)
    {
        return (dataOverlay.getMap().getZoom() ?? 0) >= 10.5;
    }

    rerender()
    {
        if(this.lastDataOverlay && this.lastData)
            this.render(this.lastDataOverlay, this.lastData);
    }

    clear(dataOverlay: DataOverlay)
    {
        dataOverlay.removeLayers([this.iconLayerId, this.labelLayerId, this.arrowLayerId]);
    }

    onHover(map: google.maps.Map, info: PickingInfo)
    {
        map.setOptions({ draggableCursor: info.object ? 'pointer' : 'grab' });

        if(this.areLabelsRendered) return;

        this.hoveredObjectId = info.object?.id;
        this.rerender();
    }
}

class GeoObjectWithArrowHeadingIterator implements Iterator<GeoObjectEntry>
{
    constructor(private it: Iterator<GeoObjectEntry>)
    {

    }

    next(): IteratorResult<GeoObjectEntry>
    {
        do
        {
            const res = this.it.next();
            if(res.done || res.value.geoObject.icon.headingWithArrow)
                return res;
        } while(true);
    }
}

class GeoObjectWithArrowHeadingIterable implements Iterable<GeoObjectEntry>
{
    constructor(private it: Iterable<GeoObjectEntry>)
    {

    }

    [Symbol.iterator](): Iterator<GeoObjectEntry>
    {
        return new GeoObjectWithArrowHeadingIterator(this.it[Symbol.iterator]());
    }
}