import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class DataPreferencesService
{
    private selectedSourceIds?: string[];
    private lowerAltitude?: number;
    private upperAltitude?: number;
    private change$ = new Subject<void>();

    onChange(): Observable<void>
    {
        return this.change$.asObservable();
    }

    setSelectedSources(selectedIds : string[])
    {
        if(this.selectedSourceIds === selectedIds)
            return;
        this.selectedSourceIds = selectedIds;
        this.change$.next();
    }

    setLowerAltitude(lowerAltitude?: number)
    {
        if(this.lowerAltitude === lowerAltitude)
            return;
        this.lowerAltitude = lowerAltitude;
        this.change$.next();
    }

    setUpperAltitude(upperAltitude?: number)
    {
        if(this.upperAltitude === upperAltitude)
            return;
        this.upperAltitude = upperAltitude;
        this.change$.next();
    }

    getSelectedSources(): string[] | undefined
    {
        return this.selectedSourceIds;
    }

    getLowerAltitude(): number | undefined
    {
        return this.lowerAltitude;
    }

    getUpperAltitude(): number | undefined
    {
        return this.upperAltitude;
    }
}