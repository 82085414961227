import { ReceiverDetail } from '../receiver';
import { GeoObjectGroup } from './geo-object';
import { Record } from './involi-format';

export interface TrafficDataDto
{
    geoObjectGroups?: GeoObjectGroup[];
    records?: Record[];
    trackers?: Record[];
    receivers?: ReceiverDetail[];
}

export interface ReceiverDataDto
{
    receivers?: ReceiverDetail[];
}