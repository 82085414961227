import { NgModule } from '@angular/core';
import { AlertApiModule } from '@involi/api-client';
import { AlertService } from '../alert/alert.service';
import { DataPreferencesService } from './data-preferences.service';
import { DisplayPreferencesService } from './display-preferences.service';
import { TrafficSourcesService } from './traffic-sources.service';

@NgModule({
    imports: [
        AlertApiModule
    ],
    providers: [
        AlertService,
        DataPreferencesService,
        DisplayPreferencesService,
        TrafficSourcesService
    ]
})
export class DataModule {}