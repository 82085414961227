import { ReceiverDetail } from '@involi/api-shared';
import { Selectable } from '../data/selection-controller';
import { IconDefinition } from '../map';

export class ReceiverSubEntry
{
    item!: ReceiverDetail;
    icon!: IconDefinition;
}

export interface ReceiverEntry extends Selectable
{
    id: string;
    receiver: ReceiverSubEntry;
}