import { isDate } from "class-validator";

const SEC_TO_MS = 1000;
const MIN_TO_MS = 60 * SEC_TO_MS;
const HOUR_TO_MS = 60 * MIN_TO_MS;
const DAY_TO_MS = 24 * HOUR_TO_MS;

export function dateDiffDays(d1: number | Date, d2: number | Date): number
{
    return dateDiff(d1, d2, DAY_TO_MS);
}

export function dateDiffSeconds(d1: number | Date, d2: number | Date): number
{
    return dateDiff(d1, d2, SEC_TO_MS);
}

function dateDiff(d1: number | Date, d2: number | Date, unit: number): number
{
    const duration = Math.floor(Math.abs(d1.valueOf() - d2.valueOf()) / unit);
    return duration;
}

export function dateAddDays(date: Date, days: number): Date;
export function dateAddDays(timestamp: number, days: number): number;
export function dateAddDays(d: number | Date, days: number): number | Date
{
    return dateAddTime(d, days, DAY_TO_MS);
}

export function dateAddHours(date: Date, hours: number): Date;
export function dateAddHours(timestamp: number, hours: number): number;
export function dateAddHours(d: number | Date, hours: number): number | Date
{
    return dateAddTime(d, hours, HOUR_TO_MS);
}


export function dateAddMinutes(date: Date, minutes: number): Date;
export function dateAddMinutes(timestamp: number, minutes: number): number;
export function dateAddMinutes(d: number | Date, minutes: number): number | Date
{
    return dateAddTime(d, minutes, MIN_TO_MS);
}

function dateAddTime(d: number | Date, time: number, unit: number): number | Date
{
    let date: number = d.valueOf() + time * unit;
    if(isDate(d))
    {
        return new Date(date);
    }
    return date;
}