import { Layer, PickingInfo } from '@deck.gl/core';
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import { Subject } from 'rxjs';

export enum LayerLevel
{
    Background = 1,
    TrafficFeatures = 2,
    Receivers = 3,
    Traffic = 4
}

interface LayerInfo
{
    layer: Layer;
    level: LayerLevel;
}

export class DataOverlay
{
    private overlay: GoogleMapsOverlay;
    private layersInfo: { [id: string]: LayerInfo } = {};
    private layers: Layer[] = [];
    mapClick$ = new Subject<PickingInfo>();

    constructor(private map: google.maps.Map)
    {
        this.overlay = new GoogleMapsOverlay({
            useDevicePixels: 2,
            style: { zIndex: '200' },
            onClick: (info: PickingInfo) => {
                this.mapClick$.next(info);
            },
            onLoad: () => map.setCenter(map.getCenter()!) // workaround overlay not properly centered over google map
        });
        this.overlay.setMap(map);
    }

    getMap(): google.maps.Map
    {
        return this.map;
    }

    setLayer(layer: Layer, level: LayerLevel)
    {
        this.layersInfo[layer.id] = { layer, level };
        this.makeSortedLayers();
        this.render();
    }

    setLayers(layers: Layer[], level: LayerLevel)
    {
        for(let layer of layers)
            this.layersInfo[layer.id] = { layer, level };
        this.makeSortedLayers();
        this.render();
    }

    removeLayer(id: string)
    {
        delete this.layersInfo[id];
        this.makeSortedLayers();
        this.render();
    }

    removeLayers(ids: string[])
    {
        for(let id of ids)
            delete this.layersInfo[id];
        this.makeSortedLayers();
        this.render();
    }

    private makeSortedLayers()
    {
        this.layers = Object.values(this.layersInfo)
            .sort((a: LayerInfo, b: LayerInfo) => a.level - b.level)
            .map(l => l.layer);
    }

    private render()
    {
        this.overlay.setProps({ layers: this.layers });
    }
}