import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class GetOrCreateReceiverEntityDto
{
    @IsString()
    @IsOptional()
    collectionName?: string;

    @IsString()
    @IsNotEmpty()
    serialNumber!: string;
}