import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { GeoObjectGroup, GeoObjectIndex, LabelSegment, LabelSegmentType, PredefinedIconVariation, TrafficDataDto } from '@involi/api-shared';
import { DataOverlay } from '../data/data-overlay';
import { SelectableDataController } from '../data/data-controller';
import { GeoObjectEntry, GeoObjectSubEntry } from './geo-object.entry';
import { GeoObjectRenderer } from './geo-object.renderer';
import { MapIconService } from '../map/services/map-icon.service';
import { RecordEntry, RecordSubEntry } from './record.entry';
import { DataProvider } from '../data/data-provider';
import { mToFt, mpsToKts } from '@involi/common';
import { DisplayPreferencesService } from '../data';

const VERTICAL_SPEED_ICON_THRESHOLD_MPS = 1;

interface GeoObjectMetadata
{
    defaultColor?: string;
    groupId?: string;
}

@Injectable()
export class GeoObjectService
{
    dataController: SelectableDataController<GeoObjectEntry, GeoObjectSubEntry, GeoObjectMetadata>;
    recordDataController: SelectableDataController<RecordEntry, RecordSubEntry, GeoObjectMetadata>;
    private renderer: GeoObjectRenderer;
    private refresh$ = new Subject<void>();

    constructor(private dataProvider: DataProvider,
                iconService: MapIconService,
                private displayPreferencesService: DisplayPreferencesService,
                private zone: NgZone)
    {
        this.dataController = new SelectableDataController({
            collection: this.dataProvider.getDataCollection(),
            key: 'geoObject',
            defaultSubEntry: () => new GeoObjectSubEntry(),
            entryDerivator: (entry: GeoObjectEntry, metadata?: GeoObjectMetadata) => {
                entry.geoObject.defaultColor = metadata?.defaultColor ?? entry.geoObject.defaultColor;
                entry.geoObject.groupId = metadata?.groupId ?? entry.geoObject.groupId;
                entry.geoObject.icon = iconService.getPredefinedIcon(
                    entry.geoObject.item[GeoObjectIndex.Icon],
                    entry.selected ? PredefinedIconVariation.Selected : entry.geoObject.item[GeoObjectIndex.IconVariation],
                    entry.geoObject.defaultColor,
                    entry.geoObject.groupId
                );
                entry.geoObject.label = this.makeLabel(entry);
            },
            selectionController: this.dataProvider.getSelectionController()
        });

        this.recordDataController = new SelectableDataController({
            collection: this.dataProvider.getDataCollection(),
            key: 'record',
            defaultSubEntry: () => new RecordSubEntry(),
            selectionController: this.dataProvider.getSelectionController()
        });

        this.renderer = new GeoObjectRenderer('geo-object', {
            fontSize: 12,
            borderColor: [222, 227, 236, 255],
            borderWidth: 1.5,
            textColor: [0, 0, 0, 255]
        },
        {
            fontSize: 12,
            borderColor: [85, 83, 218, 255],
            borderWidth: 1.5,
            textColor: [0, 0, 0, 255]
        }, zone);
    }

    private makeLabel(d: GeoObjectEntry): string
    {
        let label = '';
        const segments: LabelSegment[] = d.geoObject.item[GeoObjectIndex.Label];
        for(let segment of segments)
        {
            switch(segment[0])
            {
            case LabelSegmentType.Title:
                label += segment[1];
                break;

            case LabelSegmentType.Altitude:
                label += ` | ${Math.floor(mToFt(segment[1]))/*.toLocaleString('en-US', { maximumFractionDigits: 0 }).replace(',', "'")*/} ft`;
                if(segment[2] > VERTICAL_SPEED_ICON_THRESHOLD_MPS)
                    label += ' ▲';
                else if(segment[2] < -VERTICAL_SPEED_ICON_THRESHOLD_MPS)
                    label += ' ▼';
                break;

            case LabelSegmentType.Speed:
                label += ` | ${Math.floor(mpsToKts(segment[1]))/*.toLocaleString('en-US', { maximumFractionDigits: 0 }).replace(',', "'")*/} kts`;
                break;
            }
        }
        return label;
    }

    init(dataOverlay: DataOverlay)
    {
        this.dataController.attachOverlay(dataOverlay, this.renderer);
        /// remove listener when done
        dataOverlay.getMap().addListener('zoom_changed', () => {
            this.zone.run(() => this.renderer.rerender());
        });

        const data$ = this.dataProvider.watchTraffic((data: TrafficDataDto) => {
            this.dataController.getCollectionLayer().resetEntriesGrouped(
                'item',
                data.geoObjectGroups ?? [],
                (group: GeoObjectGroup) => group.geoObjects,
                (group: GeoObjectGroup) => ({
                    defaultColor: this.displayPreferencesService.getSourceColor(group.sourceId),
                    groupId: group.sourceId
                })
            );
        });

        this.dataController.attachSource(data$);

        const recordData$ = this.dataProvider.watchTraffic((data: TrafficDataDto) => {
            this.recordDataController.getCollectionLayer().resetEntries('item', (data.records ?? []).map(r => ({ ...r, id: r.meta!.primary_id! })));
        });
        this.recordDataController.attachSource(recordData$);

        this.displayPreferencesService.onChange().subscribe(() => {
            this.dataController.getCollectionLayer().applyToAllEntries((entry: GeoObjectEntry) => {
                entry.geoObject.defaultColor = entry.geoObject.groupId ? this.displayPreferencesService.getSourceColor(entry.geoObject.groupId) : undefined;
            });
        });
    }

    refresh()
    {
        this.refresh$.next();
    }

    getDataController(): SelectableDataController<GeoObjectEntry, GeoObjectSubEntry, GeoObjectMetadata>
    {
        return this.dataController;
    }
}