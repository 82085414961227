import { AlertDefinition } from '@involi/api-shared';
import { ZoneRenderData } from './zone-render-data';
import { Selectable } from '../data/selection-controller';

export class AlertSubEntry
{
    item!: AlertDefinition;
    polygons: ZoneRenderData[] = [];
}

export interface AlertEntry extends Selectable
{
    alert: AlertSubEntry;
}