import { Observable } from 'rxjs';
import { DataView } from './data-view';
import { MapValuesIterable } from './data-collection';

export abstract class DataFilter<Entry extends object> implements DataView<Entry>
{
    protected entries = new Map<string, Entry>();

    abstract consume(data: Observable<Iterable<Entry>>): void;

    abstract watch(): Observable<Iterable<Entry>>;

    getEntries(): Iterable<Entry>
    {
        return new MapValuesIterable(this.entries);
    }

    findEntries(fn: (entry: Entry) => boolean): Iterable<Entry>
    {
        return new MapValuesIterable(new Map([...this.entries].filter(([k, v]) => fn(v))));
    }

    size(): number
    {
        return this.entries.size;
    }

    tryGet(id: string): Entry | undefined
    {
        return this.entries.get(id);
    }
}