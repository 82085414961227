export function renameProperty<T extends {}, Old extends keyof T, New extends string>(o: T, oldKey: Old, newKey: New): Omit<T, Old> & Record<New, T[Old]>
{
    if(o.hasOwnProperty(oldKey))
    {
        Object.defineProperty(o, newKey, Object.getOwnPropertyDescriptor(o, oldKey)!);
        delete o[oldKey];
    }
    return <any>o;
}

export function renamePropertyCopy<T extends {}, Old extends keyof T, New extends string>(o: T, oldKey: Old, newKey: New): Omit<T, Old> & Record<New, T[Old]>
{
    const copy = { ...o };
    return renameProperty(copy, oldKey, newKey);
}
