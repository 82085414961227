import { IsArray, IsBoolean, IsNumber, IsOptional, IsString } from "class-validator";

export class GetDocumentDto
{
    @IsString()
    collectionName!: string;

    @IsNumber()
    @IsOptional()
    limit?: number;

    @IsNumber()
    @IsOptional()
    skip?: number;
}

export class GetDocumentFieldsDto
{
    @IsString()
    collectionName!: string;

    @IsArray()
    fields!: string[];

    @IsBoolean()
    @IsOptional()
    allowNull?: boolean;

    @IsNumber()
    @IsOptional()
    limit?: number;

    @IsNumber()
    @IsOptional()
    skip?: number;
}

export class GetDocumentFieldsPaginatedDto
{
    @IsString()
    collectionName!: string;

    @IsArray()
    fields!: string[];

    @IsBoolean()
    @IsOptional()
    allowNull?: boolean;

    @IsNumber()
    limit!: number;

    @IsString()
    @IsOptional()
    fromIdExcluded?: string;
}

export interface GetDocumentsFieldsPaginatedResponseDto
{
    fields: any[];
    lastId: string;
}