import { ErrorContext, InvoliError } from './involi-error.js';
import { toHttpStatus } from './involi-error-category.js';

export interface HttpProblem
{
    type: string;
    title: string;
    status?: number;
    detail?: string;
    instance?: string;
    context?: ErrorContext;
    requestId?: string;
    causes?: HttpProblem[];
}

function isEmpty(o: object): boolean
{
    for(let _ in o)
        return false;
    return true;
}

export function toProblem(error: InvoliError, requestId?: string, includeCauses?: boolean): HttpProblem
{
    let causes: HttpProblem[] | undefined;
    if(includeCauses)
    {
        causes = [];
        for(let cause = error.cause; cause; cause = cause.cause)
            causes.push(toProblem(cause));
    }
    const context: ErrorContext | undefined = isEmpty(error.context) ? undefined : error.context;

    return {
        type: makeProblemType(error.meta.code),
        title: error.meta.title,
        status: toHttpStatus(error.meta.category),
        detail: error.meta.detail?.(error),
        context,
        requestId,
        causes
    };
}

const problemTypePrefix = '/problem/';
export function makeProblemType(code: string): string
{
    return `${problemTypePrefix}${code}`;
}

export function getProblemCode(type: string): string
{
    if(type.startsWith(problemTypePrefix))
        return type.substring(problemTypePrefix.length);
    return 'unknown-code';
}