import { PathLayer } from '@deck.gl/layers';
import { DataView } from '../data/data-view';
import { DataOverlay, LayerLevel } from '../data/data-overlay';
import { Renderer } from '../data/renderer';
import { TrajectoryEntry } from './trajectory.entry';
import { RGBA } from '../map/features/map-features';

export interface TrajectoryRendererOptions
{
    lineWidth: number;
    lineColor: RGBA;
}
export class TrajectoryRenderer implements Renderer<TrajectoryEntry>
{
    private pathLayerId: string;

    private lastDataOverlay?: DataOverlay;
    private lastData?: DataView<TrajectoryEntry>;

    constructor(id: string, private options: TrajectoryRendererOptions)
    {
        this.pathLayerId = `${id}-path`;
    }

    render(dataOverlay: DataOverlay, data: DataView<TrajectoryEntry>)
    {
        this.lastDataOverlay = dataOverlay;
        this.lastData = data;

        const pathLayer = new PathLayer({
            id: 'path-layer',
            data: data.getEntries(),
            getPath: (t: TrajectoryEntry) => t.trajectory.path,
            getWidth: this.options.lineWidth,
            getColor: this.options.lineColor,
            widthUnits: 'pixels',
            pickable: false
        });

        dataOverlay.setLayer(pathLayer, LayerLevel.TrafficFeatures);
    }

    rerender()
    {
        if(this.lastDataOverlay && this.lastData)
            this.render(this.lastDataOverlay, this.lastData);
    }

    clear(dataOverlay: DataOverlay)
    {
        dataOverlay.removeLayer(this.pathLayerId);
    }
}
