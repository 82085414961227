import { Injectable } from '@angular/core';
import { SettingsApiService } from '@involi/api-client';
import { InvoliLiveSettingsEntity, InvoliLiveSettingsKey } from '@involi/api-shared';
import { Store } from '@ngrx/store';
import { concat, Observable, of, retry, shareReplay, switchMap, take, tap } from 'rxjs';
import { selectMapAttributes } from '../map';

@Injectable({
    providedIn: 'root'
})
export class SettingsService
{
    private readonly SETTINGS_STORAGE_KEY = 'involi-live-settings';
    private settings$: Observable<InvoliLiveSettingsEntity | undefined>;

    constructor(private settingsApiService: SettingsApiService, private store: Store)
    {
        const apiSettings$ = settingsApiService.getDirectSubjectSettings<InvoliLiveSettingsEntity>(InvoliLiveSettingsKey).pipe(
            retry({ delay: 1000, count: 3 }),
            tap((settings: InvoliLiveSettingsEntity | undefined) => this.storeLiveSettings(settings)),
            shareReplay(1)
        );

        const cachedSettings: InvoliLiveSettingsEntity | undefined = this.getStoredLiveSettings();
        if(cachedSettings)
            this.settings$ = concat(of(cachedSettings), apiSettings$);
        else
            this.settings$ = apiSettings$;
    }

    getLiveSettings(): Observable<InvoliLiveSettingsEntity | undefined>
    {
        return this.settings$;
    }

    private getStoredLiveSettings(): InvoliLiveSettingsEntity | undefined
    {
        const cachedValue: string | null = localStorage.getItem(this.SETTINGS_STORAGE_KEY);
        return cachedValue ? JSON.parse(cachedValue) : undefined;
    }

    private storeLiveSettings(settings: InvoliLiveSettingsEntity | undefined)
    {
        const value = settings ? JSON.stringify(settings) : '';
        localStorage.setItem(this.SETTINGS_STORAGE_KEY, value);
    }

    storeCurrentMapAsSettings(): Observable<InvoliLiveSettingsEntity>
    {
        return this.store.select(selectMapAttributes).pipe(
            take(1),
            switchMap(({ zoom, center, typeId }) => {
                let settings = new InvoliLiveSettingsEntity();
                settings.zoom_level = zoom;
                settings.center_latitude = center.latitude;
                settings.center_longitude = center.longitude;
                settings.map_type = typeId;

                return this.settingsApiService.setDirectSubjectLiveSettings(settings).pipe(
                    tap((apiSettings: InvoliLiveSettingsEntity) => this.storeLiveSettings(apiSettings))
                );
            })
        );
    }
}