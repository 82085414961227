import { IsLatitude, IsLongitude, IsNumber } from "class-validator";
import { SettingsEntity } from "../entity/settings.entity.js";
import { createSettings } from "../settings/settings.js";

export const TrafficRegionSettingsKey = 'traffic_region';

export class TrafficRegionSettingsEntity extends SettingsEntity
{
    @IsNumber()
    @IsLatitude()
    north_latitude!: number;

    @IsNumber()
    @IsLatitude()
    south_latitude!: number;

    @IsNumber()
    @IsLongitude()
    east_longitude!: number;

    @IsNumber()
    @IsLongitude()
    west_longitude!: number;
}

createSettings(TrafficRegionSettingsKey, TrafficRegionSettingsEntity);