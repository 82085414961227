import { Directive, Input, ViewContainerRef, TemplateRef, OnInit, DestroyRef } from '@angular/core';
import { PermissionsService } from './permissions.service';
import { distinctUntilChanged } from 'rxjs';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Directive({
    selector: '[permissionFlag]'
})
export class PermissionFlagDirective implements OnInit
{
    @Input() permissionFlag!: string;

    constructor(private viewContainer: ViewContainerRef,
                private template: TemplateRef<any>,
                private permissionsService: PermissionsService,
                private destroyRef: DestroyRef)
    {

    }

    ngOnInit()
    {
        this.permissionsService.hasPermission(this.permissionFlag).pipe(
            takeUntilDestroyed(this.destroyRef),
            distinctUntilChanged()
        ).subscribe((hasPermission: boolean) => {
            if(hasPermission)
                this.viewContainer.createEmbeddedView(this.template);
            else
                this.viewContainer.clear();
        });
    }
}
