import { ArrayNotEmpty, IsBoolean, IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import { AlertRule, AlertRuleDto } from './rule.js';
import { AlertZone, AlertZoneDto } from './zone.js';

export interface AlertDefinition
{
    id: string;
    name: string;
    enabled: boolean;
    watched: boolean;
    active: boolean;
    rules: AlertRule[];
    zones: AlertZone[];
}

export class AlertDefinitionDto
{
    @IsString()
    @IsOptional()
    id?: string;

    @IsString()
    name!: string;

    @IsBoolean()
    enabled!: boolean;

    @ArrayNotEmpty()
    @IsNotEmpty()
    @ValidateNested()
    @Type(() => AlertRuleDto)
    rules!: AlertRule[];

    @ArrayNotEmpty()
    @ValidateNested()
    @Type(() => AlertZoneDto)
    zones!: AlertZone[];
}

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }
export type UpdateAlertDefinitionDto = WithRequired<AlertDefinitionDto, 'id'>;
