import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiConfig, API_CONFIG } from '../api';
import { ApiClient } from '../core/api-client';
import { ApiStatusService } from '../core';
import { Observable } from 'rxjs';
import { OrganizationEntity } from '@involi/api-shared';

@Injectable({
    providedIn: 'root'
})
export class OrganizationApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                http: HttpClient,
                apiStatus: ApiStatusService)
    {
        super(apiStatus, http, 'Organization');
        this.apiUrl = `${config.involiApiUrl}/organization`;
    }

    createOrganization(entity: OrganizationEntity): Observable<void>
    {
        return this.http.post<void>(this.apiUrl, entity);
    }

    createDefaultOrganization(entity: OrganizationEntity): Observable<void>
    {
        return this.http.post<void>(`${this.apiUrl}/default`, entity);
    }
}