import { SettingsEntity } from "../entity/settings.entity.js";

interface SettingsConstructor
{
    new (): SettingsEntity
}

const settingsConstructorMap: { [key: string]: SettingsConstructor } = {};

export function createSettings(key: string, constructor: SettingsConstructor)
{
    if(key in settingsConstructorMap)
        throw new Error(`Settings ${key} are defined twice`);
    settingsConstructorMap[key] = constructor;
}

export function settingsEntityConstructor(key: string)
{
    return settingsConstructorMap[key] ?? SettingsEntity;
}