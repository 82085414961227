import { NgModule } from '@angular/core';
import { KeycloakAngularModule } from 'keycloak-angular';
import { UserApiModule } from '../user';

@NgModule({
    imports: [
        UserApiModule,
        KeycloakAngularModule
    ]
})
export class AuthModule {}