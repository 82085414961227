import { IsEnum } from 'class-validator';

export enum RuleType
{
    AircraftIntrusion = 'aircraft_intrusion'
}

export interface AlertRule
{
    type: RuleType;
}

export class AlertRuleDto
{
    @IsEnum(RuleType)
    type!: RuleType;
}
