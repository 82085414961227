import { Component } from '@angular/core';
import { ApiStatusService } from '@involi/api-client';
import { Observable, tap, delay } from 'rxjs';
import { AuthService } from '@involi/api-client';

@Component({
    selector: 'admin-platform',
    templateUrl: 'admin-platform.component.html',
    styleUrls: ['admin-platform.component.scss']
})
export class AdminPlatformComponent
{
    apiStatus$: Observable<boolean>;
    activeRequests$: Observable<number>;
    koApis: string[] = [];

    constructor(apiStatusService: ApiStatusService,
                private auth: AuthService)
    {
        this.apiStatus$ = apiStatusService.globalStatus$.pipe(
            tap((status: boolean) => {
                if(!status)
                    this.koApis = apiStatusService.getKoApis();
            })
        );
        this.activeRequests$ = apiStatusService.activeRequests$.pipe(delay(0));
    }

    async copyToken()
    {
        await this.auth.getValidJwtToken();
        const token = this.auth.getJwtToken();
        if(token)
            navigator.clipboard.writeText(token);
    }
}