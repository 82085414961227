<span>{{ user }} ({{ realm }})</span>
<button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="changeRealm()">
        <mat-icon>domain</mat-icon>
        <span>Change realm</span>
    </button>
    <button mat-menu-item (click)="logout()">
        <mat-icon>login</mat-icon>
        <span>Logout</span>
    </button>
</mat-menu>