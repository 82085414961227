export enum AlertState
{
    Active = 'active',
    Inactive = 'inactive',
    Deleted = 'deleted'
}

export interface AlertStatus
{
    id: string;
    state: AlertState;
}