import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig, API_CONFIG } from '../api';
import { Observable } from 'rxjs';
import { ApiClient } from '../core/api-client';
import { ApiStatusService } from '../core';
import { GetDocumentDto, GetDocumentFieldsDto, GetDocumentFieldsPaginatedDto, GetDocumentsFieldsPaginatedResponseDto } from '@involi/api-shared';

@Injectable({
    providedIn: 'root'
})
export class TrafficDataApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                http: HttpClient,
                apiStatus: ApiStatusService)
    {
        super(apiStatus, http, 'Traffic Data');
        this.apiUrl = `${config.involiApiUrl}/traffic`;
    }

    getCollections(): Observable<string[]>
    {
        return this.get<string[]>(`${this.apiUrl}/collection`, []);
    }

    getDocuments(collectionName: string, limit?: number, skip?: number): Observable<any[]>
    {
        const query: GetDocumentDto = { collectionName, limit, skip };
        return this.post<any[]>(`${this.apiUrl}/document`, query, []);
    }

    getDocumentFields(collectionName: string, fields: string[], allowNull: boolean, limit?: number, skip?: number): Observable<any>
    {
        const query: GetDocumentFieldsDto = { collectionName, fields, allowNull, limit, skip };
        return this.post<any[]>(`${this.apiUrl}/document/fields`, query, []);
    }

    getDocumentFieldsPaginated(collectionName: string, fields: string[], limit: number, fromIdExcluded?: string, allowNull?: boolean): Observable<GetDocumentsFieldsPaginatedResponseDto>
    {
        const query: GetDocumentFieldsPaginatedDto = { collectionName, fields, allowNull, limit, fromIdExcluded };
        return this.post<GetDocumentsFieldsPaginatedResponseDto>(`${this.apiUrl}/document/fields/paginated`, query);
    }
}