import { Record, TrackerEntity } from '@involi/api-shared';
import { Selectable } from '../data/selection-controller';

export class TrackerSubEntry
{
    item!: Record<TrackerEntity>;
}

export interface TrackerEntry extends Selectable
{
    id: string;
    tracker: TrackerSubEntry;
}