import { Type } from 'class-transformer';
import { ArrayMinSize, ArrayMaxSize, IsArray, IsBoolean, IsDate, IsNotEmpty, IsOptional, IsString, Matches, ValidateNested, MaxLength, IsLatitude, IsLongitude } from 'class-validator';

export class BaseAffixInstanceConfiguration
{
    @IsString()
    @IsNotEmpty()
    @Matches(/^[\w\-\.]+$/, { message: "name must only contain alphanumeric characters, '.', '_' and '-'" })
    @MaxLength(100)
    name!: string;

    @IsDate()
    @IsOptional()
    @Type(() => Date)
    start?: Date;

    @IsDate()
    @IsOptional()
    @Type(() => Date)
    end?: Date;
}

export class AffixInstanceConfiguration extends BaseAffixInstanceConfiguration
{
    @IsString()
    @IsNotEmpty()
    affixConfiguration!: string;
}

export class AffixRecordingBoundingBox
{
    @IsLatitude()
    southLatitude!: number;

    @IsLongitude()
    westLongitude!: number;

    @IsLatitude()
    northLatitude!: number;

    @IsLongitude()
    eastLongitude!: number;
}

class BaseAffixRecordingParameters
{
    @IsArray()
    @ArrayMinSize(1)
    @IsString({ each: true})
    @Matches('^([a-z0-9]+\.[a-z0-9]+\.[a-z0-9]+\.[a-z0-9])', undefined, { each: true })
    dataStreams!: string[];

    @IsBoolean()
    @IsOptional()
    matchAll?: boolean;

    @IsArray()
    @IsOptional()
    serialNumbers?: string[];

    @IsArray()
    @IsOptional()
    icaos?: string[];

    @IsArray()
    @IsOptional()
    squawks?: string[];

    @IsArray()
    @IsOptional()
    rawLengths?: number[];

    @IsOptional()
    @ValidateNested()
    boundingBox?: AffixRecordingBoundingBox;
}

export class AffixRecordingOptionalParameters extends BaseAffixRecordingParameters
{
    @IsArray()
    @IsOptional()
    receivers?: string[];
}

export class AffixRecordingRequiredParameters extends BaseAffixRecordingParameters
{
    @IsArray()
    @ArrayMinSize(1)
    @ArrayMaxSize(30)
    @IsNotEmpty()
    receivers!: string[];
}

export type AffixRecordingParameters = AffixRecordingOptionalParameters | AffixRecordingRequiredParameters;

export class AffixRecordingInstanceConfiguration extends BaseAffixInstanceConfiguration
{
    @Type(() => AffixRecordingRequiredParameters)
    @ValidateNested()
    recordingParameters!: AffixRecordingRequiredParameters;
}

export class AffixRecordingInstanceUnrestrictedConfiguration extends BaseAffixInstanceConfiguration
{
    @Type(() => AffixRecordingOptionalParameters)
    @ValidateNested()
    recordingParameters!: AffixRecordingOptionalParameters;
}