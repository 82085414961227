import { EntityKind } from '../entity/entity-kind.js';

export interface WhoHasPermissionEntityDto
{
    type: EntityKind;
    id: string;
}

export interface WhoHasPermissionDto
{
    entities: WhoHasPermissionEntityDto[];
}