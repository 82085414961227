import { asyncScheduler, Observable, SchedulerAction, Subscriber } from 'rxjs';

export function steadyInterval(period: number): Observable<number> 
{
    return new Observable<number>(subscriber => {
        subscriber.add(
            asyncScheduler.schedule(dispatch as any, period/2, { subscriber, counter: period/2, period, expected: Date.now() + period/2})
        );
        return subscriber;
    });
}

function dispatch(this: SchedulerAction<IntervalState>, state: IntervalState) {
    const { subscriber, counter, period, expected } = state;
    subscriber.next(counter);
    var dt = Date.now() - expected;
    this.schedule({ subscriber, counter: counter + 1, period, expected: expected + period }, Math.max(0, period - dt));
}

interface IntervalState
{
    subscriber: Subscriber<number>;
    counter: number;
    period: number;
    expected: number;
}