import { Injectable } from '@angular/core';
import { SelectableDataController } from '../data/data-controller';
import { TrackerEntry, TrackerSubEntry } from './tracker.entry';
import { TrafficDataDto } from '@involi/api-shared';
import { DataProvider } from '../data/data-provider';
import { TrackerFilter } from './tracker.filter';

@Injectable()
export class TrackersService
{
    private dataController: SelectableDataController<TrackerEntry, TrackerSubEntry>;

    constructor(private dataProvider: DataProvider, trackerFilter: TrackerFilter)
    {
        this.dataController = new SelectableDataController({
            collection: this.dataProvider.getDataCollection(),
            key: 'tracker',
            defaultSubEntry: () => new TrackerSubEntry(),
            selectionController: this.dataProvider.getSelectionController(),
            filter: trackerFilter
        });
    }

    init()
    {
        const trackerData$ = this.dataProvider.watchTraffic((data: TrafficDataDto) => {
            if(!data.trackers) return;
            this.dataController.getCollectionLayer().resetEntries('item', data.trackers.map(r => ({ ...r, id: r.meta!.primary_id! })));
        });
        this.dataController.attachSource(trackerData$);
    }

    getDataController(): SelectableDataController<TrackerEntry, TrackerSubEntry>
    {
        return this.dataController;
    }
}