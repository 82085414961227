import { Component } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';

export interface SideMenuNode
{
    title: string;
    url: string;
    icon?: string;
    children?: SideMenuNode[];
    rootLeaf?: boolean;
}

@Component({
    selector: 'side-nav',
    templateUrl: 'side-nav.component.html',
    styleUrls: ['side-nav.component.scss']
})
export class SideNavComponent
{
    treeControl = new NestedTreeControl<SideMenuNode>(node => node.children);

    readonly menuData: SideMenuNode[] = [
        { title: 'Users', url: '/users', children: [
            { title: 'Tokens', url: '/users/tokens' }
        ]},
        { title: 'Data', url: '/data', children: [
            { title: 'Ledger', url: '/data/ledger' },
            { title: 'Coverage', url: '/data/coverage' }
        ]},
        { title: 'MCTs', url: '/mct', children: [
            { title: 'Overview', url: '/mct/overview' }
        ]},
        { title: 'Entity', url: '/entity', children : [
            { title: 'Overview', url: '/entity/overview' },
            { title: 'Receiver', url: '/entity/receiver' },
            { title: 'Permission', url: '/entity/permission' },
            { title: 'Organization', url: '/entity/organization' },
            { title: 'User-Organization', url: '/entity/user-organization' },
            { title: 'Client', url: '/entity/client' }
        ]},
        { title: 'API', url: '/api', children : [
            { title: 'Overview', url: '/api/overview' },
            { title: 'Migration', url: '/api/migration' },
            { title: 'Node', url: '/api/node' }
        ]},
        { title: 'Tools', url: '/tools', children : [
            { title: 'Websocket', url: '/tools/websocket' },
            { title: 'RabbitMQ Consumer', url: '/tools/rabbitmq-consumer' },
            { title: 'Involi API Client', url: '/tools/involi-api-client' },
            { title: 'DB Viewer', url: '/tools/db-viewer' },
            { title: 'Affix', url: '/tools/affix' }
        ]},
        { title: 'Manage Organizations', url: '/organization-management', rootLeaf: true }
    ];

    hasChild = (_: number, node: SideMenuNode) => !!node.children && node.children.length > 0;
}