import { Injectable } from '@angular/core';
import { AlertDefinition, AlertState, AlertStatus, ZonePoint } from '@involi/api-shared';
import { AlertApiService } from '@involi/api-client';
import { Subject, map, merge, switchMap, tap, timer } from 'rxjs';
import { AlertEntry, AlertSubEntry } from './alert.entry';
import { AlertRenderer } from './alert.renderer';
import { DataOverlay } from '../data/data-overlay';
import { SelectableDataController } from '../data/data-controller';
import { DataCollection } from '../data/data-collection';
import { ZoneRenderData } from './zone-render-data';
import { RGBA } from '../map/features/map-features';

@Injectable()
export class AlertService
{
    private alertDataController: SelectableDataController<AlertEntry, AlertSubEntry>;
    private alertRenderer = new AlertRenderer('alert');
    private refresh$ = new Subject<void>();

    constructor(private alertApi: AlertApiService)
    {
        const collection = new DataCollection();
        this.alertDataController = new SelectableDataController({
            collection,
            key: 'alert',
            defaultSubEntry: () => new AlertSubEntry(),
            entryDerivator: this.entryDerivator.bind(this)
        });
    }

    private entryDerivator(entry: AlertEntry)
    {
        function getAlertColor(alertDefinition: AlertDefinition, opacity: number): RGBA
        {
            if(!alertDefinition.enabled)
                return [135, 142, 151, opacity];
            return entry.alert.item.active ? [234, 0, 0, opacity] : [234, 211, 0, opacity]
        }

        const zonesData: ZoneRenderData[] = [];
        const alertLineColor: RGBA = getAlertColor(entry.alert.item, 255);
        const alertFillColor: RGBA = getAlertColor(entry.alert.item, 52);
        if(entry.selected)
            alertFillColor[3] = 128;
        for(let zone of entry.alert.item.zones)
        {
            zonesData.push({
                alert: entry.alert.item,
                polygon: zone.points.map((pt: ZonePoint) => [pt.longitude, pt.latitude]),
                lineColor: alertLineColor,
                fillColor: alertFillColor
            });
        }
        entry.alert.polygons = zonesData;
    }

    init(dataOverlay: DataOverlay)
    {
        this.alertDataController.attachOverlay(dataOverlay, this.alertRenderer);

        const all$ = merge(
            timer(0, 60000),
            this.refresh$
        ).pipe(
            switchMap(() => this.alertApi.getUserAlerts()),
            tap((alerts: AlertDefinition[]) => this.alertDataController.getCollectionLayer().setProperty('item', alerts))
        );

        const stream$ = this.alertApi.observeAlerts().pipe(
            map((alertStatus: AlertStatus[]) => {
                this.alertDataController.getCollectionLayer().patchProperty(
                    'item',
                    alertStatus.map((status: AlertStatus) =>
                        status.state == AlertState.Deleted
                        ? { id: status.id, $delete: true }
                        : { id: status.id, active: status.state == AlertState.Active }
                    )
                );
            })
        );
        this.alertDataController.attachSource(all$);
        this.alertDataController.attachSource(stream$);
    }

    refresh()
    {
        this.refresh$.next();
    }

    getDataController(): SelectableDataController<AlertEntry, AlertSubEntry>
    {
        return this.alertDataController;
    }
}