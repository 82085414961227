export interface CollectionStatsDto
{
    name: string;
    records: number;
    bytes: number;
}

export interface CollectionsStatsDto
{
    collections: CollectionStatsDto[];
}