
import { Record } from '../data';

export function makeAircraftPreferredId(record: Record): string
{
    if(!record.aircraft)
        return "-";

    const shortId: string = record.aircraft.call_sign ??
                            record.aircraft.registration ??
                            record.meta?.label!;
    return shortId.toUpperCase()
}