import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs';
import { AuthService, ConfigurationService, UserApiService } from '@involi/api-client';
import { RealmSelectionComponent } from '../realm-selection/realm-selection.component';
import { UserEntity } from '@involi/api-shared';

@Component({
    selector: 'user-header',
    templateUrl: 'user-header.component.html',
    styleUrls: ['user-header.component.scss']
})
export class UserHeaderComponent
{
    user: string = '...';
    realm: string;

    constructor(private dialog: MatDialog,
                private authService: AuthService,
                private configurationService: ConfigurationService,
                private userApi: UserApiService)
    {
        const userUuid: string = this.authService.getSubject()!;
        this.userApi.getUser(userUuid).subscribe({
            next: (user: UserEntity) => this.user = user.email,
            error: (_: HttpErrorResponse) => this.user = userUuid
        });
        this.realm = this.configurationService.configuration?.involiAuthConfig.currentRealm ?? '?';
    }

    changeRealm()
    {
        this.dialog.open(RealmSelectionComponent).afterClosed().pipe(
            filter(Boolean)
        ).subscribe((realm: string) => {
            this.configurationService.setAuthRealm(realm);
            this.logout()
        });
    }

    logout()
    {
        this.authService.logout();
    }
}