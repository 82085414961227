import { Component, Input } from '@angular/core';

@Component({
    selector: 'involicon',
    template: `
        <svg [attr.width]="width" [attr.height]="height" xmlns="http://www.w3.org/2000/svg">
            <use attr.xlink:href="assets/icons.svg#{{ icon }}"></use>
        </svg>
    `,
    host: {
        '[style.width]': 'width',
        '[style.height]': 'height'
    }
})
export class InvoliconComponent
{
    @Input() width!: string;
    @Input() height!: string;
    @Input() icon!: string;
}