import { AlertEntry } from './alert.entry';
import { ZoneRenderData } from './zone-render-data';

export class ZoneIterator implements Iterator<ZoneRenderData>
{
    private zoneIt?: Iterator<ZoneRenderData>;

    constructor(private it: Iterator<AlertEntry>)
    {

    }

    next(): IteratorResult<ZoneRenderData>
    {
        let nextZone: IteratorResult<ZoneRenderData> | undefined = this.zoneIt?.next();
        if(nextZone && !nextZone.done) return nextZone;
        const next = this.it.next();
        if(next.done) return { value: undefined, done: true };
        this.zoneIt = next.value.alert.polygons[Symbol.iterator]();
        nextZone = this.zoneIt.next();
        return nextZone;
    }
}

export class ZoneIterable implements Iterable<ZoneRenderData>
{
    constructor(private values: Iterable<AlertEntry>)
    {

    }

    [Symbol.iterator](): Iterator<ZoneRenderData>
    {
        return new ZoneIterator(this.values[Symbol.iterator]());
    }
}