export enum InvoliErrorCategory
{
    Ok,
    BadRequest,
    Unauthorized,
    Forbidden,
    NotFound,
    Conflict,
    PayloadTooLarge,
    Unprocessable,
    TooManyRequests,
    RequestHeaderFieldsTooLarge,
    InternalServerError,
    ServiceUnavailable
}

export function toHttpStatus(category: InvoliErrorCategory): number | undefined
{
    switch(category)
    {
    case InvoliErrorCategory.Ok: return 200;
    case InvoliErrorCategory.BadRequest: return 400;
    case InvoliErrorCategory.Unauthorized: return 401;
    case InvoliErrorCategory.Forbidden: return 403;
    case InvoliErrorCategory.NotFound: return 404;
    case InvoliErrorCategory.Conflict: return 409;
    case InvoliErrorCategory.PayloadTooLarge: return 413;
    case InvoliErrorCategory.Unprocessable: return 422;
    case InvoliErrorCategory.TooManyRequests: return 429;
    case InvoliErrorCategory.RequestHeaderFieldsTooLarge: return 431;
    case InvoliErrorCategory.InternalServerError: return 500;
    case InvoliErrorCategory.ServiceUnavailable: return 503;
    }
}

export function fromHttpStatus(status: number): InvoliErrorCategory | undefined
{
    switch(status)
    {
    case 200: return InvoliErrorCategory.Ok;
    case 400: return InvoliErrorCategory.BadRequest;
    case 401: return InvoliErrorCategory.Unauthorized;
    case 403: return InvoliErrorCategory.Forbidden;
    case 404: return InvoliErrorCategory.NotFound;
    case 409: return InvoliErrorCategory.Conflict;
    case 413: return InvoliErrorCategory.PayloadTooLarge;
    case 422: return InvoliErrorCategory.Unprocessable;
    case 429: return InvoliErrorCategory.TooManyRequests;
    case 431: return InvoliErrorCategory.RequestHeaderFieldsTooLarge;
    case 500: return InvoliErrorCategory.InternalServerError;
    case 503: return InvoliErrorCategory.ServiceUnavailable;
    }
}