import { IsArray, IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export interface EntityMeta
{
    additionalPermissions?: string[];
}

export class Entity
{
    @IsString()
    @IsNotEmpty()
    id!: string;

    meta?: EntityMeta & any;
}

export class AddEntityMemberDto
{
    @IsString()
    @IsNotEmpty()
    id!: string;

    @IsBoolean()
    @IsOptional()
    applyPermissions?: boolean;
}

export class AddEntityMembersDto
{
    @IsString()
    @IsNotEmpty()
    property!: string;

    @IsString()
    @IsNotEmpty()
    regex!: string;
}

export class AddEntityMembersBulkDto
{
    @IsArray()
    ids!: string[];
}

export class GetPointOfViewDto<E extends Entity = Entity>
{
    @IsOptional()
    pov?: E;
}

export class GetDefaultPointOfViewDto<E extends Entity = Entity>
{
    @IsOptional()
    defaultPov?: E;
}