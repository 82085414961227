import { MemberRelationship } from "./member.relationship.js";
import { Relationship } from "./relationship.js";

export enum RelationshipType
{
    MemberOf = 'MEMBER_OF',
    PointOfView = 'POINT_OF_VIEW',
    DefaultPointOfView = 'DEFAULT_POINT_OF_VIEW',
    Can = 'CAN',
    MetadataFor = 'METADATA_FOR',
    For = 'FOR',
    Is = 'IS',
    DependsOn = 'DEPENDS_ON'
}

export function relationshipConstructor(relationshipType: RelationshipType)
{
    switch(relationshipType)
    {
        case RelationshipType.MemberOf:
            return MemberRelationship;
        default:
            return Relationship;
    };
}