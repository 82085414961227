import { HttpProblem, InvoliError, InvoliErrorCategory, fromHttpStatus, getProblemCode, makeError } from '@involi/api-shared';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Observable, catchError, of, tap, switchMap, finalize } from 'rxjs';
import { ApiStatusService } from './api-status.service';
import { v4 as makeUuid } from 'uuid';

const serviceUnavailable = makeError({
    category: InvoliErrorCategory.ServiceUnavailable,
    code: 'service-unavailable',
    title: 'Service unavailable'
});

export class ApiClient
{
    private isServiceAvailable: boolean = true;
    private lastError?: InvoliError;
    private onGoingRequests: string[] = [];

    constructor(protected apiStatus: ApiStatusService,
                protected http: HttpClient,
                protected apiName: string)
    {
        this.apiStatus.registerApi(apiName);
    }

    protected get<T>(url: string, defaultValue?: T): Observable<T>
    {
        return this.handleError(this.http.get<T>(url), defaultValue);
    }

    protected post<T>(url: string, body: any | null, defaultValue?: T): Observable<T>
    {
        return this.handleError(this.http.post<T>(url, body), defaultValue);
    }

    protected put<T>(url: string, body: any, defaultValue?: T): Observable<T>
    {
        return this.handleError(this.http.put<T>(url, body), defaultValue);
    }

    protected delete<T>(url: string, defaultValue?: T): Observable<T>
    {
        return this.handleError(this.http.delete<T>(url), defaultValue);
    }

    private handleError<T>(request: Observable<T>, defaultValue?: T): Observable<T>
    {
        const uuid: string = makeUuid();
        return of(null).pipe(
            tap(() => this.onRequestStart(uuid)),
            switchMap(() => request),
            tap(() => {
                if(!this.isServiceAvailable)
                    this.setAvailability(true);
            }),
            catchError((error: HttpErrorResponse) => {
                this.setAvailability(false);
                const contentType = error.headers.get('content-type');
                if(contentType?.includes('application/problem+json'))
                {
                    const problem: HttpProblem = error.error;
                    this.lastError = new InvoliError({
                        category: fromHttpStatus(error.status) ?? InvoliErrorCategory.InternalServerError,
                        code: getProblemCode(problem.type),
                        title: problem.title
                    }, problem.context ?? {});
                }
                else if(error.status == HttpStatusCode.ServiceUnavailable
                    || error.status == HttpStatusCode.Forbidden
                    || error.status == HttpStatusCode.NotFound
                    || error.status == HttpStatusCode.Conflict
                    || error.status == HttpStatusCode.UnprocessableEntity
                    || error.status == HttpStatusCode.BadRequest)
                {
                    this.lastError = new InvoliError({
                        category: fromHttpStatus(error.status) ?? InvoliErrorCategory.InternalServerError,
                        code: 'request-failed',
                        title: 'Request failed'
                    }, { error: JSON.stringify(error) });
                }
                else
                {
                    this.lastError = serviceUnavailable();
                }
                if(defaultValue)
                    return of(defaultValue);
                throw this.lastError;
            }),
            finalize(() => this.onRequestEnd(uuid))
        );
    }

    isAvailable(): boolean
    {
        return this.isServiceAvailable;
    }

    private setAvailability(available: boolean)
    {
        this.isServiceAvailable = available;
        if(this.isServiceAvailable)
            this.lastError = undefined;
        this.apiStatus.setApiStatus(this.apiName, this.isServiceAvailable);
    }

    private onRequestStart(requestUuid: string)
    {
        this.onGoingRequests.push(requestUuid);
        this.apiStatus.setActiveApiRequests(this.apiName, this.onGoingRequests.length);
    }

    private onRequestEnd(requestUuid: string)
    {
        const requestIndex = this.onGoingRequests.indexOf(requestUuid);
        if(requestIndex >= 0)
            this.onGoingRequests.splice(requestIndex, 1);
        this.apiStatus.setActiveApiRequests(this.apiName, this.onGoingRequests.length);
    }

    getLastError(): InvoliError | undefined
    {
        return this.lastError;
    }
}