import { Entity } from '../entity';

export interface Mlat
{
    method: string;
    raw: string;
    source_mcts: string[];
    used_records_uuid: string[];
}

export enum AircraftType
{
    unknown = 'unknown',
    small_plane = 'small_plane',
    large_plane = 'large_plane',
    helicopter = 'helicopter',
    paraglider = 'paraglider',
    parachute = 'parachute',
    balloon = 'balloon',
    drone = 'drone',
    ground_obstacle = 'ground_obstacle',
    ground_vehicle = 'ground_vehicle',
    ground_operator = 'ground_operator'
}

export interface Aircraft
{
    ids: { [key: string]: string };
    latitude?: number;
    longitude?: number;
    position_timestamp?: [number, number];
    wgs84_altitude?: number;
    barometric_altitude?: number;
    ground_speed?: number;
    vertical_speed?: number;
    ground_status?: GroundStatus;
    timestamp?: [number, number];
    course?: number;
    heading?: number;
    aircraft_type?: AircraftType;
    mlat?: Mlat;
    types?: { [key: string]: string };
    registration?: string;
    call_sign?: string;
    squawk?: string;
    horizontal_position_accuracy?: number;
    altitude_accuracy?: number;
}

export interface DeviceStatus
{
    battery: number;
    is_online?: boolean;
}

export interface GpsReceiver
{
    hdop: number;
    is_available: boolean;
}

export interface ConnectedCellularNetworkStatus
{
    earfcn: number;
    pcid: number;
    rsrp: number;
    rsrq: number;
    rssi: number;
    sinr: number;
}

export interface CellularNetworkStatus
{
    signal_strength: number;
    connected_cellular_network_status: ConnectedCellularNetworkStatus;
}

export enum AlarmStatus
{
    alert = 'Alert',
    warning = 'Warning',
    clear = 'Clear',
    rien = 'Rien',
    noData = 'No Data'
}

export enum GroundStatus
{
    onGround = 'on_ground',
    airborne = 'airborne',
    unknown = 'unknown'
}

export interface Meta
{
    receiving_timestamp?: [number, number];
    primary_id?: string;
    label?: string;
}

export interface UserData
{
    customName?: string;
}

export interface DetectionComponent
{
    type: string;
    id: string;
    timestamp: [number, number];
    rssi?: number;
    rsqi?: number;
}

export interface Detection
{
    receiver_type: string;
    receiver_id: string;
    components: DetectionComponent[];
}

export interface ReceiverComponent
{
    id: string;
    type: string;
    state?: string;
    last_received_message?: [number, number];
}

export interface Receiver
{
    ids: { [key: string]: string };
    type?: string;
    label?: string;
    latitude?: number;
    longitude?: number;
    wgs84_altitude?: number;
    temperature?: number;
    relative_humidity?: number;
    pressure?: number;
    last_gnss_fix?: [number, number];
    last_update?: [number, number];
    is_enabled?: boolean;
    range?: number;
    components?: ReceiverComponent[];
    power_consumption?: number;

    is_online?: boolean; // additional property not in involi-format
}

export interface ZoneVertex
{
    latitude: number;
    longitude: number;
}

export interface Zone
{
    id: string;
    label?: string;
    type: string;
    vertices?: ZoneVertex[];
    receivers?: string[];
}

export interface RawMessage
{
    metadata: number[];
    raw_data: number[];
}

export interface Record<E = Entity>
{
    aircraft?: Aircraft;
    device_status?: DeviceStatus;
    gps_receiver?: GpsReceiver;
    cellular_network_status?: CellularNetworkStatus;
    meta?: Meta;
    userData?: UserData;
    display_groups?: string[];
    detections?: Detection[];
    receiver?: Receiver;
    zone?: Zone;
    raw_message?: RawMessage;
    source_specific?: any;

    // Additional info not directly in DB record
    sourceId?: string;
    sourceLabel?: string;
    entity?: E;
}
