export const permissions: string[] = [];

export function createPermission(type: string, name: string): string
{
    const permissionId = getPermissionId(type, name);
    if(permissions.includes(permissionId))
        throw new Error(`Permission ${permissionId} is defined twice`);
    permissions.push(permissionId);
    return permissionId;
}

export function getPermissionId(type: string, name: string): string
{
    return `${type}:${name}`;
}

export const PermissionRead = 'read';
export const PermissionWrite = 'write';
export const PermissionUpdate = 'update';