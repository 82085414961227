import { GeoObject } from '@involi/api-shared';
import { Selectable } from '../data/selection-controller';
import { IconDefinition } from '../map/services/map-icon.service';

export class GeoObjectSubEntry
{
    item!: GeoObject;
    label!: string;
    icon!: IconDefinition;
    defaultColor?: string;
    groupId?: string;
}

export interface GeoObjectEntry extends Selectable
{
    geoObject: GeoObjectSubEntry;
}