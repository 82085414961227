import { IsOptional, IsString } from 'class-validator';
import { Entity } from '../entity/entity.js';
import { makePrimaryId } from '../entity/utils.js';

export class TrackerEntity extends Entity
{
    @IsString()
    model?: string;

    @IsString()
    serialNumber?: string;

    @IsOptional()
    @IsString()
    collection?: string;

    constructor(baseEntity: Partial<TrackerEntity>)
    {
        super();
        this.serialNumber = baseEntity.serialNumber;
        this.collection = baseEntity.collection;
        if(baseEntity.serialNumber && baseEntity.collection)
            this.id = this.id = makePrimaryId(baseEntity.collection, baseEntity.serialNumber);
    }
}