import { IsArray, IsNotEmpty, IsString } from 'class-validator';

export class UpdateLatestMctFirmwareDto
{
    @IsString()
    @IsNotEmpty()
    expectedArtifactName!: string;

    @IsArray()
    receiverIds!: string[];
}