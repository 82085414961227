import { IsArray, IsBoolean, IsOptional } from 'class-validator';
import { GetTrafficDto } from '../traffic/get-traffic-from-layers.dto.js';

export class GetDataDto extends GetTrafficDto
{
    @IsOptional()
    @IsBoolean()
    includeTraffic?: boolean;

    @IsOptional()
    @IsArray()
    additionalAircraftIds?: string[];

    @IsOptional()
    @IsBoolean()
    includeSubjectTrackers?: boolean;
}