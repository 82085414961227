import { Type } from 'class-transformer';
import { ArrayMinSize, IsNumber, IsOptional, ValidateNested, ValidationArguments, ValidationOptions, registerDecorator } from 'class-validator';

const earthCircumferenceKm = 40075;
const latitudeDegreeToKm = 111;

export function IsSmallerThanKm(maxSize: number, validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: 'isSmallerThanKm',
            target: object.constructor,
            propertyName: propertyName,
            constraints: [maxSize],
            options: validationOptions,
            validator: {
                validate(value: ZonePoint[], args: ValidationArguments)
                {
                    if(!value)
                        return false;

                    const [maxSize] = args.constraints;
                    const southLat = Math.min(...value.map((p: ZonePoint) => p.latitude));
                    const northLat = Math.max(...value.map((p: ZonePoint) => p.latitude));
                    const latDistance = (northLat - southLat) * latitudeDegreeToKm;
                    if(latDistance > maxSize)
                        return false;

                    //longitude offsets from the starting point
                    let lonDiffs: number[] = [0];
                    for(let i = 1; i < value.length; i++)
                    {
                        let diff = value[i].longitude - value[i-1].longitude;
                        if(diff > 180)
                            diff -= 360;
                        else if(diff < -180)
                            diff += 360;
                        lonDiffs.push(lonDiffs[lonDiffs.length - 1] + diff);
                    }

                    //estimating maximum width of polygon
                    const closestToEquator = Math.min(Math.abs(southLat), Math.abs(northLat)) * (Math.PI/180);
                    const maxLonDiff = (Math.max(...lonDiffs) - Math.min(...lonDiffs));
                    let lonDistance = Math.acos(
                        Math.sin(closestToEquator) * Math.sin(closestToEquator)
                        + Math.cos(closestToEquator) * Math.cos(closestToEquator) * Math.cos(maxLonDiff * (Math.PI/180))
                    )*6371;

                    if(maxLonDiff > 180)
                        lonDistance = earthCircumferenceKm - lonDistance;
                    return lonDistance <= maxSize;
                },
            },
        });
    };
}

function intersect(segment1: [ZonePoint, ZonePoint], segment2: [ZonePoint, ZonePoint]): boolean
{
    function ccw(a: ZonePoint, b: ZonePoint, c: ZonePoint): boolean
    {
        return (b.longitude - a.longitude) * (c.latitude - a.latitude) < (c.longitude - a.longitude) * (b.latitude - a.latitude);
    }

    let shifted = false;
    let a1 = { ...segment1[0] };
    let a2 = { ...segment1[1] };
    if((a1.longitude * a2.longitude < 0) && Math.abs(a1.longitude - a2.longitude) > 180)
    {
        //crossing antimeridian line, shifting eastern point to (180, 360) range
        if(a1.longitude < 0)
            a1.longitude += 360;
        else
            a2.longitude += 360;
        shifted = true;
    }
    let b1 = { ...segment2[0] };
    let b2 = { ...segment2[1] };
    if((b1.longitude * b2.longitude < 0) && Math.abs(b1.longitude - b2.longitude) > 180)
    {
        if(b1.longitude < 0)
            b1.longitude += 360;
        else
            b2.longitude += 360;
    }
    else if(shifted && b1.longitude < 0 && b2.longitude < 0)
    {
        b1.longitude += 360;
        b2.longitude += 360;
    }

    return (ccw(a1, a2, b1) != ccw(a1, a2, b2)) && (ccw(b1, b2, a1) != ccw(b1, b2, a2));
}

export function IsNotSelfIntersectingPolygon(validationOptions?: ValidationOptions) {
    function selfIntersects(points: ZonePoint[]): boolean
    {
        for(let i = 0; i < points.length; i++)
        {
            for(let j = 0; j < points.length; j++)
            {
                //connected or identical segments
                if(j == i || (j + 1) % points.length == i || j == (i + 1) % points.length)
                    continue;

                if(intersect([points[i], points[(i+1) % points.length]], [points[j], points[(j+1) % points.length]]))
                    return true;
            }
        }
        return false;
    }

    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: 'isNotSelfIntersectingPolygon',
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            validator: {
                validate(value: ZonePoint[], args: ValidationArguments)
                {
                    if(value.length <= 3)
                        return true;

                    return !selfIntersects(value);
                },
            },
        });
    };
}

export interface ZonePoint
{
    latitude: number;
    longitude: number;
}

export interface AlertZone
{
    points: ZonePoint[];
    upper_altitude?: number;
    lower_altitude?: number;
}

export class ZonePointDto implements ZonePoint
{
    @IsNumber()
    latitude!: number;

    @IsNumber()
    longitude!: number;
}

export class AlertZoneDto
{
    @ArrayMinSize(3)
    @ValidateNested()
    @IsSmallerThanKm(20000, { message: 'Polygon is too large!' })
    @IsNotSelfIntersectingPolygon({ message: 'Polygon self-intersects!' })
    @Type(() => ZonePointDto)
    points!: ZonePointDto[];

    @IsNumber()
    @IsOptional()
    upper_altitude?: number;

    @IsNumber()
    @IsOptional()
    lower_altitude?: number;
}
