import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig, API_CONFIG } from '../api';
import { Observable, map } from 'rxjs';
import { DataStreamEntity, GetSettingsDto, SettingsEntity } from '@involi/api-shared';
import { ApiStatusService } from '../core/api-status.service';
import { ApiClient } from '../core/api-client';

@Injectable({
    providedIn: 'root'
})
export class DataStreamApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                apiStatus: ApiStatusService,
                http: HttpClient)
    {
        super(apiStatus, http, 'Data stream');
        this.apiUrl = `${config.involiApiUrl}/data-stream`;
    }

    getSubjectDataStreams(): Observable<DataStreamEntity[]>
    {
        return this.get<DataStreamEntity[]>(this.apiUrl);
    }
}