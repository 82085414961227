import { IsArray, ValidateNested } from 'class-validator';
import { UserEntity } from '../entity/user.entity.js';
import { Type } from 'class-transformer';

export class UpdateUsersDto
{
    @IsArray()
    @Type(() => UserEntity)
    @ValidateNested({ each: true })
    users!: UserEntity[];
}