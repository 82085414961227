import { _MultiIconLayer } from '@deck.gl/layers';
import vs from './z-indexed-multi-icon-vertex.glsl';

export class ZIndexedMultiIconLayer extends _MultiIconLayer<any>
{
    static layerName = 'ZIndexedMultiIconLayer';

    getShaders()
    {
        return Object.assign({}, super.getShaders(), { vs });
    }
}
