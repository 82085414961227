export enum PredefinedIcon
{
    Drone,
    LargePlane,
    SmallPlane,
    Helicopter,
    Paraglider,
    Parachute,
    Balloon,
    GroundVehicle,
    GroundObstacle,
    InvoliMct,
    InvoliMctOffline
}

export enum PredefinedIconVariation
{
    Default,
    Online,
    Offline,
    OnGround,
    Selected
}