import { BehaviorSubject, Observable, Subject, defer, filter, finalize, map, share, startWith } from 'rxjs';
import { DataView } from './data-view';

export interface DataId
{
    id: string;
};

export interface DeleteOp
{
    id: string;
    $delete: true;
}

export interface ClassConstructor<T>
{
    new (...args: any[]): T
}

export function trackSubscription(callback: (subscribed: boolean) => void)
{
    return (source$: Observable<any>) => defer(() => {
        setTimeout(() => callback(true));
        return source$;
    }).pipe(
        finalize(() => callback(false)),
        share()
    );
}

export class MapValuesIterable<K, V> implements Iterable<V>
{
    constructor(private map: Map<K, V>)
    {

    }

    [Symbol.iterator](): Iterator<V>
    {
        return this.map.values();
    }

    get size()
    {
        return this.map.size;
    }
}

type Derivator<Entry, EntryMetadata> = (entry: Entry, metadata?: EntryMetadata) => void;

class DataCollectionEntry
{
    refCount: number = 0;
    constructor(public id: string) {}
}

export class DataCollection
{
    private entries = new Map<string, DataCollectionEntry>();
    private changes = new Subject<void>();

    private bulkChange: boolean = false;
    private subscribedSubject = new BehaviorSubject<boolean>(false);
    subscribed$: Observable<boolean> = this.subscribedSubject.asObservable();
    changes$: Observable<void> = this.changes.asObservable().pipe(
        trackSubscription((sub: boolean) => this.subscribedSubject.next(sub)),
        filter(() => !this.bulkChange)
    );

    private derivators = new Set<Derivator<any, any>>();

    attachDerivator<Entry, EntryMetadata>(derivator: Derivator<Entry, EntryMetadata>)
    {
        this.derivators.add(derivator);
    }

    deriveEntry<Entry, EntryMetadata>(entry: Entry, metadata?: EntryMetadata)
    {
        for(let derivator of this.derivators)
            derivator(entry, metadata);
    }

    size(): number
    {
        return this.entries.size;
    }

    getValues(): Iterable<DataCollectionEntry>
    {
        return new MapValuesIterable(this.entries);
    }

    get<T extends object = DataCollectionEntry>(id: string): T | undefined
    {
        return this.entries.get(id) as T;
    }

    lock(id: string): DataCollectionEntry
    {
        let entry = this.entries.get(id);
        if(!entry)
        {
            entry = new DataCollectionEntry(id);
            this.entries.set(id, entry);
        }
        entry.refCount++;
        return entry;
    }

    release(id: string)
    {
        const entry = this.entries.get(id);
        if(entry)
        {
            entry.refCount--;
            if(!entry.refCount)
                this.entries.delete(id);
        }
    }

    releaseBulk(ids: Iterable<string>)
    {
        for(let id of ids)
            this.release(id);
    }

    unbind(id: string)
    {
        const entry = this.entries.get(id);
        if(entry)
            entry.refCount--;
    }

    unbindBulk(ids: Iterable<string>)
    {
        for(let id of ids)
            this.unbind(id);
    }

    cleanupUnboundEntries()
    {
        for(let entry of this.entries.values())
            if(!entry.refCount)
                this.entries.delete(entry.id);
    }

    notifyChange()
    {
        this.changes.next();
    }

    bulkChangeStart()
    {
        if(this.bulkChange)
            throw new Error('[DataCollection] Nested bulk change');
        this.bulkChange = true;
    }

    bulkChangeEnd()
    {
        this.bulkChange = false;
        this.changes.next();
    }
}

export class DataCollectionLayer<Entry extends object, SubEntry extends Entry[keyof Entry], EntryMetadata = void> implements DataView<Entry>
{
    private entries = new Map<string, Entry>();

    constructor(private collection: DataCollection,
                private key: keyof Entry,
                private defaultSubEntry: () => SubEntry,
                entryDerivator?: (entry: Entry, metadata?: EntryMetadata) => void)
    {
        if(entryDerivator)
        {
            collection.attachDerivator((entry: DataCollectionEntry & Entry, metadata?: EntryMetadata) => {
                if(this.entries.has(entry.id))
                    entryDerivator(entry, metadata);
            });
        }
    }

    size(): number
    {
        return this.entries.size;
    }

    getDataCollection(): DataCollection
    {
        return this.collection;
    }

    getEntries(): Iterable<Entry>
    {
        return new MapValuesIterable(this.entries);
    }

    findEntries(fn: (entry: Entry) => boolean): Iterable<Entry>
    {
        return new MapValuesIterable(new Map([...this.entries].filter(([k, v]) => fn(v))));
    }

    tryGet(id: string): Entry | undefined
    {
        return this.entries.get(id);
    }

    private lock(id: string): Entry
    {
        const entry: Entry = this.collection.lock(id) as Entry;
        this.entries.set(id, entry);
        return entry;
    }

    get(id: string): Entry
    {
        let entry: Entry | undefined = this.entries.get(id);
        if(!entry)
        {
            entry = this.lock(id);
            entry[this.key] = this.defaultSubEntry();
        }
        return entry;
    }

    private subEntry(entry: Entry): SubEntry
    {
        return entry[this.key] as SubEntry;
    }

    release(id: string)
    {
        this.collection.release(id);
        this.entries.delete(id);
    }

    releaseAll()
    {
        this.collection.releaseBulk(this.entries.keys());
        this.entries.clear();
        this.collection.notifyChange();
    }

    private unbindAll()
    {
        this.collection.unbindBulk(this.entries.keys());
        this.entries.clear();
    }

    resetEntries<K extends keyof SubEntry, Item extends DataId & SubEntry[K]>(subEntryKey: K, items: Item[], metadata?: EntryMetadata): void;
    resetEntries<K extends keyof SubEntry, Item extends SubEntry[K] extends any[] ? SubEntry[K] : never>(subEntryKey: K, items: Item[], metadata?: EntryMetadata): void;
    resetEntries<K extends keyof SubEntry>(subEntryKey: K, items: any[], metadata?: EntryMetadata): void
    {
        this.unbindAll();
        this.doResetEntries(subEntryKey, items, metadata);
        this.collection.cleanupUnboundEntries();
        this.collection.notifyChange();
    }

    resetEntriesGrouped<K extends keyof SubEntry, Item extends DataId & SubEntry[K], Group>(
        subEntryKey: K,
        groups: Group[],
        projector: (group: Group) => Item[],
        metadataProjector?: (group: Group) => EntryMetadata
    ): void;
    resetEntriesGrouped<K extends keyof SubEntry, Item extends SubEntry[K] extends any[] ? SubEntry[K] : never, Group>(
        subEntryKey: K,
        groups: Group[],
        projector: (group: Group) => Item[],
        metadataProjector?: (group: Group) => EntryMetadata
    ): void;
    resetEntriesGrouped<K extends keyof SubEntry, Group>(
        subEntryKey: K,
        groups: Group[],
        projector: (group: Group) => any[],
        metadataProjector?: (group: Group) => EntryMetadata
    ): void
    {
        this.unbindAll();
        for(let group of groups)
            this.doResetEntries(subEntryKey, projector(group), metadataProjector ? metadataProjector(group) : undefined);
        this.collection.cleanupUnboundEntries();
        this.collection.notifyChange();
    }

    private doResetEntries<K extends keyof SubEntry, EntryMetadata>(subEntryKey: K, items: any[], metadata?: EntryMetadata): void
    {
        for(let item of items)
        {
            const id: string = Array.isArray(item) ? item[0] : item.id;
            const entry: Entry = this.get(id);
            this.subEntry(entry)[subEntryKey] = item;
            this.collection.deriveEntry(entry, metadata);
        }
    }

    private isDeleted<T extends object>(item: T | DeleteOp): item is DeleteOp
    {
        return '$delete' in item && item.$delete;
    }

    // Assign a property to an entry. If the entry does not exist, it is created, the resulting default constructed entry
    // with the key `entryKey` assigned must be valid
    setProperty<K extends keyof SubEntry, Item extends SubEntry[K]>(subEntryKey: K, items: ((Item & DataId) | DeleteOp)[]): void;
    setProperty<K extends keyof SubEntry, Item extends SubEntry[K] extends any[] ? SubEntry[K] : never>(subEntryKey: K, items: Item[]): void;
    setProperty<K extends keyof SubEntry>(subEntryKey: K, items: any[])
    {
        for(let item of items)
        {
            const id = Array.isArray(item) ? item[0] : item.id;
            if(this.isDeleted(item))
            {
                this.release(id);
                continue;
            }

            let entry: Entry = this.get(id);
            this.subEntry(entry)[subEntryKey] = item;
            this.collection.deriveEntry(entry);
        }
        this.collection.notifyChange();
    }

    setEntryProperty<K extends keyof SubEntry, Item extends SubEntry[K]>(subEntryKey: K, id: string, item: Item)
    {
        let entry: Entry = this.get(id);
        this.subEntry(entry)[subEntryKey] = item;
        this.collection.deriveEntry(entry);
        this.collection.notifyChange();
    }

    // Patch a property of an entry which must already exist
    patchProperty<
        K extends keyof { [key in keyof SubEntry as SubEntry[key] extends object ? key : never]: unknown },
        Item extends SubEntry[K]
    >(subEntryKey: K, items: ((Partial<Item> & DataId) | DeleteOp)[]): void;
    patchProperty<
        K extends keyof { [key in keyof SubEntry as SubEntry[key] extends object ? key : never]: unknown },
        Item extends SubEntry[K] extends any[] ? SubEntry[K] : never
    >(subEntryKey: K, items: Item[]): void;
    patchProperty<
        K extends keyof { [key in keyof SubEntry as SubEntry[key] extends object ? key : never]: unknown }
    >(subEntryKey: K, items: any[]): void
    {
        for(let item of items)
        {
            const id = Array.isArray(item) ? item[0] : item.id;
            if(this.isDeleted(item))
            {
                this.release(id);
                continue;
            }

            const entry: Entry | undefined = this.tryGet(id);
            if(!entry)
                continue
            Object.assign(this.subEntry(entry)[subEntryKey] as object, item);
            this.collection.deriveEntry(entry);
        }
        this.collection.notifyChange();
    }

    patchEntry(id: string, value: Partial<Entry>): Entry | undefined
    {
        const entry: Entry | undefined = this.entries.get(id);
        if(!entry) return;
        Object.assign(entry, value);
        this.collection.deriveEntry(entry);
        this.collection.notifyChange();
        return entry;
    }

    getAndPatchEntry(id: string, value: Partial<Entry>): Entry
    {
        const entry: Entry = this.get(id);
        Object.assign(entry, value);
        this.collection.deriveEntry(entry);
        this.collection.notifyChange();
        return entry;
    }

    patchAllEntries(value: Partial<Entry>)
    {
        for(const entry of this.entries.values())
        {
            Object.assign(entry, value);
            this.collection.deriveEntry(entry);
        }
        this.collection.notifyChange();
    }

    applyToAllEntries(fn: (entry: Entry) => void)
    {
        for(const entry of this.entries.values())
        {
            fn(entry);
            this.collection.deriveEntry(entry);
        }
        this.collection.notifyChange();
    }

    bulkChangeStart()
    {
        this.collection.bulkChangeStart();
    }

    bulkChangeEnd()
    {
        this.collection.bulkChangeEnd();
    }

    watch(): Observable<Iterable<Entry>>
    {
        return this.collection.changes$.pipe(
            map(() => this.getEntries()),
            startWith(this.getEntries())
        );
    }
}