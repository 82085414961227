import { Observable, Subject, Subscription, combineLatest, map, share, startWith, tap } from "rxjs";
import { DataFilter } from "../data";
import { ReceiverEntry } from "./receiver.entry";
import { DestroyRef, Injectable } from "@angular/core";
import { OperationalStatus } from "@involi/api-shared";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Injectable()
export class ReceiverFilter extends DataFilter<ReceiverEntry>
{
    private refresh$ = new Subject<void>();
    private changes$ = new Subject<Iterable<ReceiverEntry>>();
    private searchString?: string;
    private statuses?: boolean[];
    private operationalStatuses?: OperationalStatus[];
    private firmwares?: string[];
    private subscription?: Subscription;

    constructor(private destroyRef: DestroyRef)
    {
        super();
    }

    consume(data: Observable<Iterable<ReceiverEntry>>)
    {
        if(this.subscription)
            this.subscription.unsubscribe();

        this.subscription = combineLatest([data, this.refresh$.pipe(startWith(null))]).pipe(
            takeUntilDestroyed(this.destroyRef),
            map(([newEntries, _]) => newEntries),
            tap((newEntries: Iterable<ReceiverEntry>) => {
                this.entries.clear();
                for(let entry of newEntries)
                {
                    if(this.filter(entry))
                        this.entries.set(entry.id, entry);
                }
            }),
            share()
        ).subscribe(this.changes$);
    }

    setSearchFilter(searchString?: string)
    {
        this.searchString = searchString;
        this.refresh$.next();
    }

    setStatusFilter(statuses?: boolean[])
    {
        this.statuses = statuses;
        this.refresh$.next();
    }

    setOperationalStatusFilter(operationalStatuses?: OperationalStatus[])
    {
        this.operationalStatuses = operationalStatuses;
        this.refresh$.next();
    }

    setFirmwareFilter(firmwares?: string[])
    {
        this.firmwares = firmwares;
        this.refresh$.next();
    }

    private filter(entry: ReceiverEntry): boolean
    {
        if(this.searchString)
        {
            let lowercaseValue = this.searchString.toLowerCase();
            if(!entry.receiver.item.label?.toLowerCase().includes(lowercaseValue) && !entry.receiver.item.ids?.serial_number?.toLowerCase().includes(lowercaseValue))
                return false;
        }

        if(this.statuses != undefined)
        {
            if(!this.statuses.includes(entry.receiver.item.is_online ?? false))
                return false;
        }

        if(this.operationalStatuses != undefined)
        {
            if(!this.operationalStatuses.includes(entry.receiver.item.operational_status ?? OperationalStatus.NotApplicable))
                return false;
        }

        if(this.firmwares != undefined)
        {
            if(!entry.receiver.item.firmware || !this.firmwares.includes(entry.receiver.item.firmware))
                return false;
        }

        return true;
    }

    watch(): Observable<Iterable<ReceiverEntry>>
    {
        return this.changes$.asObservable();
    }
}