import { Injectable } from '@angular/core';
import { PermissionApiService } from '@involi/api-client';
import { Observable, map, retry, shareReplay, take } from 'rxjs';

@Injectable()
export class PermissionsService
{
    private permissions$: Observable<string[]>;

    constructor(permissionsApiService: PermissionApiService)
    {
        this.permissions$ = permissionsApiService.getUserPermissions().pipe(
            retry({ delay: 1000, count: 3 }),
            shareReplay(1)
        );
    }

    hasPermission(permission: string): Observable<boolean>
    {
        return this.permissions$.pipe(
            map((permissions: string[]) => permissions.includes(permission))
        );
    }

    permissions(): Observable<string[]>
    {
        return this.permissions$;
    }
}