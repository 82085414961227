export default `\
#version 300 es
#define SHADER_NAME rounded-text-background-layer-fragment-shader

precision highp float;

uniform bool stroked;

in vec4 vFillColor;
in vec4 vLineColor;
in float vLineWidth;
in vec2 uv;
in vec2 dimensions;

out vec4 fragColor;

float roundedRectangleSDF(vec2 position, vec2 size, float radius)
{
    return length(max(abs(position) - size + radius, 0.0)) - radius;
}

void main(void)
{
    geometry.uv = uv;

    vec2 pixelPosition = uv * dimensions;
    float radius = dimensions.y/2.0;
    if(stroked)
    {
        float distToEdge = 1.0 - roundedRectangleSDF(pixelPosition - dimensions/2.0, dimensions/2.0, radius) - vLineWidth;
        bool isOutside = distToEdge < 0.0;
        float isBorder = smoothedge(distToEdge, vLineWidth);
        fragColor = isOutside ? vec4(0) : mix(vFillColor, vLineColor, isBorder);
    }
    else
    {
        fragColor = vFillColor;
    }

    DECKGL_FILTER_COLOR(fragColor, geometry);
}
`;
