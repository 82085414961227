import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiConfig, API_CONFIG } from '../api';
import { UserApiModule } from './user-api.module';
import { ApiClient } from '../core/api-client';
import { ApiStatusService } from '../core';
import { map, Observable } from 'rxjs';
import { HasPermissionDto, UpdateProfileDto, UserEntity } from '@involi/api-shared';

@Injectable({
    providedIn: 'root'
})
export class UserApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                http: HttpClient,
                apiStatus: ApiStatusService)
    {
        super(apiStatus, http, 'User');
        this.apiUrl = `${config.involiApiUrl}/user`;
    }

    getUser(id: string): Observable<UserEntity>
    {
        return this.http.get<UserEntity>(`${this.apiUrl}/user/${id}`);
    }

    getCurrentUser(): Observable<UserEntity>
    {
        return this.http.get<UserEntity>(`${this.apiUrl}/profile`);
    }

    updateCurrentUser(data: UpdateProfileDto): Observable<UserEntity>
    {
        return this.http.post<UserEntity>(`${this.apiUrl}/profile`, data);
    }

    updateUsers(userEntities: UserEntity[])
    {
        return this.http.post<void>(`${this.apiUrl}/update/users`, { users: userEntities });
    }

    setUserOrganization(userId: string, orgId: string): Observable<void>
    {
        return this.http.put<void>(`${this.apiUrl}/${userId}/organization/${orgId}`, {});
    }

    hasPermissionOnPOV(permissionId: string): Observable<boolean>
    {
        return this.http.get<HasPermissionDto>(`${this.apiUrl}/has-pov-permission/${permissionId}`).pipe(
            map((response: HasPermissionDto) => response.hasPermission)
        );
    }
}
