import { Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { steadyInterval } from './steadyInterval';

@Component({
    selector: 'clock',
    templateUrl: 'clock.component.html',
    styleUrls: ['clock.component.scss']
})
export class ClockComponent implements OnDestroy
{
    now: number;
    private destroy$ = new Subject<void>();

    constructor()
    {
        this.now = Date.now();
        steadyInterval(1000).pipe(
            takeUntil(this.destroy$)
        ).subscribe(() => this.now = Date.now());
    }

    ngOnDestroy()
    {
        this.destroy$.next();
        this.destroy$.complete();
    }
}