import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiConfig, API_CONFIG } from '../api';
import { ApiClient } from '../core/api-client';
import { ApiStatusService } from '../core';
import { Observable } from 'rxjs';
import { ClientCredentialsDto, ClientEntity, CreateClientDto, CreateClientResponseDto } from '@involi/api-shared';

@Injectable({
    providedIn: 'root'
})
export class SubjectApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                http: HttpClient,
                apiStatus: ApiStatusService)
    {
        super(apiStatus, http, 'Subject');
        this.apiUrl = `${config.involiApiUrl}/subject`;
    }

    listClients(): Observable<ClientEntity[]>
    {
        return this.get<ClientEntity[]>(`${this.apiUrl}/client`, []);
    }

    createClient(clientId: string): Observable<CreateClientResponseDto>
    {
        const request = new CreateClientDto();
        request.clientId = clientId;
        return this.post<CreateClientResponseDto>(`${this.apiUrl}/client`, request);
    }

    getClientCredentials(id: string): Observable<ClientCredentialsDto>
    {
        return this.get<ClientCredentialsDto>(`${this.apiUrl}/client/${id}/credentials`);
    }

    deleteClient(id: string): Observable<void>
    {
        return this.delete<void>(`${this.apiUrl}/client/${id}`);
    }
}
