import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ConfigurationService } from '../configuration';

export interface PostIsaTimestamp
{
    audience: string;
    latestRequestTimestamp: Date;
}

@Injectable()
export class RemoteIdService
{
    constructor(private configurationService: ConfigurationService,
                private http: HttpClient)
    {

    }

    getDpLatestPostIsa(): Observable<PostIsaTimestamp[]>
    {
        if(!this.configurationService.configuration)
            throw new Error('[RemoteIdService] trying to get latest post Ida without configuration');

        return this.http.get<{ [audience: string]: string }>(`https://${this.configurationService.configuration.remoteId!.dpDomain}/v1/involi/get_post_isas`).pipe(
            map((timestamps: { [audience: string]: string }) => Object.keys(timestamps).map((audience: string): PostIsaTimestamp => ({
                audience: audience,
                latestRequestTimestamp: new Date(timestamps[audience])
            })))
        );
    }
}