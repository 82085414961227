import { DataCollectionMetadata } from "../data-collection/data-collection.metadata";
import { SourceStatus } from "../source-status";

export interface SourceInfo
{
    id: string;
    label: string;
    description?: string;
    meta?: DataCollectionMetadata;
    status?: SourceStatus;
}