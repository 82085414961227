export interface TrackerParametersDto
{
    dns: string;
    port: number;
    password: string;
    apn: string;
    apn_password: string;
    report_time?: string; //seconds
    ask_for_gps: string; //seconds
    reconnect_time: string; //seconds
    timezone: string;
    speed: number;
    battery: number;
    heartbeat_period: string; //seconds
}