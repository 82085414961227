import { Injectable } from '@angular/core';
import { load } from '@loaders.gl/core';
import { KMLLoader } from '@loaders.gl/kml';
import { GeoJSONTable, ObjectRowTable } from '@loaders.gl/schema';
import { Store } from '@ngrx/store';
import { FeatureCollection } from 'geojson';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { MapChart, MapChartType } from '../map';
import { addCharts, removeChart } from '../store/map.actions';

const dbName = 'involi_live';
const storeName = 'custom_kml';

export interface KmlLoaderResponse
{
	success: boolean;
	errorMsg?: string;
}

export interface KmlGeoJsons
{
	[name: string]: FeatureCollection;
}

@Injectable({
    providedIn: 'root'
})
export class KmlLoaderService
{
	private customKmlsGeoJson: KmlGeoJsons = {};
	private customKmlsGeoJson$ = new BehaviorSubject<KmlGeoJsons>({});
	private db?: IDBDatabase;

  	constructor(private store: Store)
    {
		const dbOpenRequest = indexedDB.open(dbName, 1);
		dbOpenRequest.onerror = () => console.error(`Database open error: ${dbOpenRequest.error}`)
		dbOpenRequest.onsuccess = () => {
			this.db = dbOpenRequest.result;
			this.db.onerror = () => console.error(`Database error: ${dbOpenRequest.error}`);
			const request = this.db.transaction(storeName).objectStore(storeName).openCursor();
			request.onsuccess = (): any => {
				let cursor = request.result;
				if(cursor)
				{
					let key = cursor.primaryKey;
					let value = cursor.value;
					this.customKmlsGeoJson[key.toString()] = value;
					cursor.continue();
				}

				this.store.dispatch(addCharts({
					charts: Object.keys(this.customKmlsGeoJson).map(
						(name: string): MapChart => ({
							name: name,
							visible: false,
							type: MapChartType.Generic
						})
					)
				}));

				this.customKmlsGeoJson$.next(this.customKmlsGeoJson);
			};
		};

		dbOpenRequest.onupgradeneeded = () => {
			dbOpenRequest.result.createObjectStore(storeName);
		};
    }

	private makeKmlName(file: File): string
	{
		let name = file.name;
		if(!Object.keys(this.customKmlsGeoJson).includes(name))
			return name;

		const r = new RegExp(`^${name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')} \\((\\d+)\\)$`);
		let i = 1;

		Object.keys(this.customKmlsGeoJson).forEach(
			(name: string) => {
				let match = name.match(r);
				if(match)
					i = Math.max(i, Number(match[1]) + 1);
			}
		)

		return `${name} (${i})`;
	}

    addKml(file: File): Observable<KmlLoaderResponse>
    {
		let response = new Subject<KmlLoaderResponse>;
		load(
			file,
			KMLLoader,
			{
				gis: { format: 'geojson' },
				kml: { shape: 'geojson-table' }
			}
		).then((parsedKml: ObjectRowTable | GeoJSONTable) => {
			if(parsedKml.shape == 'object-row-table')
			{
				response.next({ success: false, errorMsg: 'KML load failed: invalid format, expect a feature collection' });
				return;
			}

			const name = this.makeKmlName(file);
			if(this.db)
			{
				const objectStore = this.db.transaction(storeName, "readwrite").objectStore(storeName);
				const request = objectStore.add(parsedKml, name);
				request.onsuccess = () => {
					this.customKmlsGeoJson[name] = parsedKml;
					this.customKmlsGeoJson$.next(this.customKmlsGeoJson);
					this.store.dispatch(addCharts({
						charts: [{
                            name: name,
                            visible: true,
                            type: MapChartType.Generic
                        }]
					}));
					response.next({ success: true });
				};
				request.onerror = () => {
					console.error(request.error);
					response.next({ success: false, errorMsg: `Database error: ${request.error}`});
				}
			}
			else
			{
				response.next({ success: false, errorMsg: 'Database not initialized'});
			}
		});

		return response;
    }

    deleteKml(name: string): Observable<KmlLoaderResponse>
    {
		if(!(name in this.customKmlsGeoJson))
			return of({ success: false, errorMsg: "unknown KML" });

		let response = new Subject<KmlLoaderResponse>;

		if(this.db)
		{
			const request = this.db.transaction(storeName, "readwrite").objectStore(storeName).delete(name);
			request.onsuccess = () => {
				delete this.customKmlsGeoJson[name];
				this.store.dispatch(removeChart({
					chartName: name
				}));
				this.customKmlsGeoJson$.next(this.customKmlsGeoJson);
				response.next({ success: true });
			};
			request.onerror = () => {
				console.log(request.error);
				response.next({ success: false, errorMsg: `Database error: ${request.error}`});
			}
		}
		else
		{
			response.next({ success: false, errorMsg: 'Database not initialized'});
		}

		return response;
    }

    getKmlGeoJsons(): Observable<KmlGeoJsons>
    {
        return this.customKmlsGeoJson$.asObservable();
    }
}

