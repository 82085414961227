<h1 mat-dialog-title>Select Realm</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Available Realms</mat-label>
        <mat-select [(value)]="selectedRealm">
            <mat-option *ngFor="let realm of realms" [value]="realm">
                {{ realm }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="primary" (click)="selectRealm()">Select Realm and Logout</button>
</div>