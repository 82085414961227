import { Injectable, NgZone } from '@angular/core';
import { DataOverlay } from '../data/data-overlay';
import { GeojsonData, GeojsonRenderer } from './geojson.renderer';
import { Observable, Subject } from 'rxjs';
import { ClickInfo } from '../map';

@Injectable()
export class GeojsonService
{
    private renderers = new Map<string, GeojsonRenderer>();
    private dataOverlay?: DataOverlay;

    private clickedFeature = new Subject<ClickInfo>();
    clickedFeature$: Observable<ClickInfo> = this.clickedFeature.asObservable();

    constructor(private zone: NgZone)
    {

    }

    init(dataOverlay: DataOverlay)
    {
        this.dataOverlay = dataOverlay;
        for(let renderer of this.renderers.values())
            renderer.setOverlay(this.dataOverlay);
    }

    add(id: string, data: GeojsonData)
    {
        this.remove(id);
        const renderer = new GeojsonRenderer(id, this.zone);
        renderer.clickedFeature$.subscribe(this.clickedFeature);
        this.renderers.set(id, renderer);
        renderer.setData(data);
        if(this.dataOverlay)
            renderer.setOverlay(this.dataOverlay);
    }

    remove(id: string)
    {
        this.renderers.get(id)?.clear();
        this.renderers.delete(id);
    }

    kmlIds(): IterableIterator<string>
    {
        return this.renderers.keys();
    }

    setVisible(id: string, visible: boolean): boolean
    {
        const renderer: GeojsonRenderer | undefined = this.renderers.get(id);
        if(!renderer)
            return false;
        if(visible)
            renderer.rerender();
        else
            renderer.clear();
        return true;
    }
}