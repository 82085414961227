import { EMPTY, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { Selectable, SelectionController } from './selection-controller';
import { DataCollection, DataCollectionLayer } from './data-collection';
import { DataOverlay } from './data-overlay';
import { Renderer } from './renderer';
import { DataFilter } from './data-filter';
import { DataView } from './data-view';

export interface DataControllerOptions<Entry extends object, SubEntry extends Entry[keyof Entry], EntryMetadata>
{
    collection: DataCollection;
    key: keyof Entry;
    defaultSubEntry: () => SubEntry;
    entryDerivator?: (entry: Entry, metadata?: EntryMetadata) => void;
    filter?: DataFilter<Entry>;
}

export class DataController<Entry extends object, SubEntry extends Entry[keyof Entry], EntryMetadata = void>
{
    protected collectionLayer: DataCollectionLayer<Entry, SubEntry, EntryMetadata>;
    protected renderer?: Renderer<Entry>;
    private attached: boolean = false;
    protected detached$ = new Subject<void>();
    private dataOverlay?: DataOverlay;
    private dataView: DataView<Entry>;

    constructor(options: DataControllerOptions<Entry, SubEntry, EntryMetadata>)
    {
        this.collectionLayer = new DataCollectionLayer<Entry, SubEntry, EntryMetadata>(options.collection, options.key, options.defaultSubEntry, options.entryDerivator);

        if(options.filter)
        {
            options.filter.consume(this.collectionLayer.watch());
            this.dataView = options.filter;
        }
        else
        {
            this.dataView = this.collectionLayer;
        }
    }

    attachOverlay(dataOverlay: DataOverlay, renderer: Renderer<Entry>)
    {
        if(this.attached)
            this.detachOverlay();

        this.dataView.watch()
            .pipe(takeUntil(this.detached$))
            .subscribe(() => renderer.render(dataOverlay, this.dataView));

        this.dataOverlay = dataOverlay;
        this.renderer = renderer;
        this.attached = true;
    }

    detachOverlay()
    {
        if(!this.attached) return;
        this.detached$.next();
        this.attached = false;
        if(this.renderer && this.dataOverlay)
            this.renderer.clear(this.dataOverlay);
        this.renderer = undefined;
        this.dataOverlay = undefined;
    }

    attachSource(o$: Observable<any>)
    {
        this.collectionLayer.getDataCollection().subscribed$.pipe(
            switchMap((subscribed: boolean) => subscribed ? o$ : EMPTY)
        ).subscribe();
    }

    getCollectionLayer(): DataCollectionLayer<Entry, SubEntry, EntryMetadata>
    {
        return this.collectionLayer;
    }

    getDataView(): DataView<Entry>
    {
        return this.dataView;
    }
}

export interface SelectableDataControllerOptions<Entry extends object, SubEntry extends Entry[keyof Entry], EntryMetadata> extends DataControllerOptions<Entry, SubEntry, EntryMetadata>
{
    selectionController?: SelectionController;
}

export class SelectableDataController<Entry extends Selectable, SubEntry extends Entry[keyof Entry], EntryMetadata = void> extends DataController<Entry, SubEntry, EntryMetadata>
{
    private selectionController: SelectionController;

    constructor(private options: SelectableDataControllerOptions<Entry, SubEntry, EntryMetadata>)
    {
        super(options);
        this.selectionController = options.selectionController ? options.selectionController : new SelectionController(options.collection);
    }

    override attachOverlay(dataOverlay: DataOverlay, renderer: Renderer<Entry>)
    {
        super.attachOverlay(dataOverlay, renderer);
        renderer.attachSelectionController?.(this.selectionController);

        // If the selection controller is not provided, handle data overlay map clicks
        // otherwise it's assumed it has already been handled elsewhere.
        if(!this.options.selectionController)
        {
            dataOverlay.mapClick$
                .pipe(takeUntil(this.detached$))
                .subscribe(() => {
                    this.selectionController.deselectAll()
                });
        }
    }

    override detachOverlay()
    {
        this.renderer?.attachSelectionController?.();
        super.detachOverlay();
    }

    getSelectionController(): SelectionController
    {
        return this.selectionController;
    }
}