import { FeatureCollection, GeometryCollection, Polygon } from "geojson";

export enum CoverageAltitudeReference
{
    AGL = 'AGL',
    WGS84 = 'WGS84'
}

export interface CoverageGeometry extends Polygon
{
    h3CellIndex: string;
    layer: {
        upper: number;
        upperReference: CoverageAltitudeReference,
        lower: number;
        lowerReference: CoverageAltitudeReference;
        uom: 'm'
    }
}

export type CoverageGeometryCollection = GeometryCollection<CoverageGeometry>;

export interface CoverageProperties
{
    //in seconds
    coverageTimestamp: number;
}

export type CoverageGeoJSON = FeatureCollection<CoverageGeometryCollection, CoverageProperties>;