import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig, API_CONFIG } from '../api';
import { TrajectoryLocations } from './trajectory';

@Injectable()
export class TrajectoryApiService
{
    constructor(@Inject(API_CONFIG) private apiConfig: ApiConfig, private http: HttpClient)
    {

    }

    getTrajectory(aircraftId: string): Observable<TrajectoryLocations>
    {
        return this.http.get<TrajectoryLocations>(`${this.apiConfig.involiApiUrl}/trajectory/aircraft/${aircraftId}`);
    }
}
