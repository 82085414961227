import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiStatusService
{
    private apisStatus: { [name: string]: boolean } = {};
    globalStatus$ = new BehaviorSubject<boolean>(true);

    private activeApiRequests: { [name: string]: number } = {};
    activeRequests$ = new BehaviorSubject<number>(0);

    registerApi(name: string)
    {
        this.apisStatus[name] = true;
        this.activeApiRequests[name] = 0;
    }

    setApiStatus(name: string, status: boolean)
    {
        this.apisStatus[name] = status;
        this.globalStatus$.next(this.getGlobalStatus());
    }

    getApiStatus(name: string): boolean
    {
        return this.apisStatus[name] ?? false;
    }

    getGlobalStatus(): boolean
    {
        return Object.values(this.apisStatus).every((status: boolean) => status);
    }

    getKoApis(): string[]
    {
        return Object.entries(this.apisStatus).filter(([_, status]) => !status).map(([name, _]) => name);
    }

    setActiveApiRequests(apiName: string, count: number)
    {
        this.activeApiRequests[apiName] = count;
        this.activeRequests$.next(Object.values(this.activeApiRequests).reduce((sum: number, count: number) => sum + count, 0));
    }
}