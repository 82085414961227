import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AddEntityMemberDto, AddEntityMembersBulkDto, AddEntityTypeDto, Entity, GetDefaultPointOfViewDto, GetPointOfViewDto } from '@involi/api-shared';
import { Observable, map } from 'rxjs';
import { ApiConfig, API_CONFIG } from '../api';
import { ApiClient } from '../core/api-client';
import { ApiStatusService } from '../core/api-status.service';

@Injectable({
    providedIn: 'root'
})
export class EntityApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                apiStatus: ApiStatusService,
                http: HttpClient)
    {
        super(apiStatus, http, 'Entity');
        this.apiUrl = `${config.involiApiUrl}/entity`;
    }

    createEntity(entityType: string, entity: Entity): Observable<void>
    {
        return this.post<void>(`${this.apiUrl}/${entityType}`, entity);
    }

    createEntities(entityType: string, entities: Entity[]): Observable<void>
    {
        return this.post<void>(`${this.apiUrl}/${entityType}/bulk`, entities);
    }

    getEntities<T>(entityType: string): Observable<T[]>
    {
        return this.get<T[]>(`${this.apiUrl}/${entityType}`, []);
    }

    deleteEntity(entityType: string, entityId: string): Observable<void>
    {
        return this.delete<void>(`${this.apiUrl}/${entityType}/${entityId}`);
    }

    getEntityMembers<T>(entityType: string, entityId: string, memberEntityType: string): Observable<T[]>
    {
        return this.get<T[]>(`${this.apiUrl}/${entityType}/${entityId}/members/${memberEntityType}`);
    }

    addEntityMember(entityType: string, entityId: string, memberEntityType: string, memberEntityId: string): Observable<void>
    {
        const request = new AddEntityMemberDto();
        request.id = memberEntityId;
        return this.post<void>(`${this.apiUrl}/${entityType}/${entityId}/members/${memberEntityType}`, request);
    }

    addEntityMembers(entityType: string, entityId: string, memberEntityType: string, memberEntityIds: string[]): Observable<void>
    {
        const request = new AddEntityMembersBulkDto();
        request.ids = memberEntityIds;
        return this.post<void>(`${this.apiUrl}/${entityType}/${entityId}/members/${memberEntityType}/bulk`, request);
    }

    removeEntityMember(entityType: string, entityId: string, memberEntityType: string, memberEntityId: string): Observable<void>
    {
        return this.delete<void>(`${this.apiUrl}/${entityType}/${entityId}/members/${memberEntityType}/${memberEntityId}`);
    }

    getEntityPointOfView<E extends Entity = Entity>(entityType: string, entityId: string): Observable<E | undefined>
    {
        return this.get<GetPointOfViewDto<E>>(`${this.apiUrl}/${entityType}/${entityId}/pov`).pipe(
            map((dto: GetPointOfViewDto<E>) => dto.pov)
        );
    }

    setEntityPointOfView(entityType: string, entityId: string, targetEntityType: string, targetEntityId: string): Observable<void>
    {
        return this.put<void>(`${this.apiUrl}/${entityType}/${entityId}/pov/${targetEntityType}/${targetEntityId}`, {});
    }

    removeEntityPointOfView(entityType: string, entityId: string): Observable<void>
    {
        return this.delete<void>(`${this.apiUrl}/${entityType}/${entityId}/pov`);
    }

    getEntityDefaultPointOfView<E extends Entity = Entity>(entityType: string, entityId: string): Observable<E | undefined>
    {
        return this.get<GetDefaultPointOfViewDto<E>>(`${this.apiUrl}/${entityType}/${entityId}/default-pov`).pipe(
            map((dto: GetDefaultPointOfViewDto<E>) => dto.defaultPov)
        );
    }

    setEntityDefaultPointOfView(entityType: string, entityId: string, targetEntityType: string, targetEntityId: string): Observable<void>
    {
        return this.put<void>(`${this.apiUrl}/${entityType}/${entityId}/default-pov/${targetEntityType}/${targetEntityId}`, {});
    }

    removeEntityDefaultPointOfView(entityType: string, entityId: string): Observable<void>
    {
        return this.delete<void>(`${this.apiUrl}/${entityType}/${entityId}/default-pov`);
    }

    addEntityType(entityType: string, newEntityType: string): Observable<number>
    {
        const request: AddEntityTypeDto = { type: newEntityType };
        return this.post<number>(`${this.apiUrl}/${entityType}/add-entity-type`, request);
    }

    setEntityProperties(entityType: string, entityId: string, properties: any): Observable<void>
    {
        return this.put<void>(`${this.apiUrl}/${entityType}/${entityId}`, properties);
    }

    getEntityMetadata<T>(entityType: string, entityId: string, refType: string, refId: string): Observable<T>
    {
        return this.get<T>(`${this.apiUrl}/${entityType}/${entityId}/metadata/for/${refType}/${refId}`);
    }

    addEntityMetadata(entityType: string, entityId: string, refType: string, refId: string, metadata: any)
    {
        return this.post<void>(`${this.apiUrl}/${entityType}/${entityId}/metadata/for/${refType}/${refId}`, metadata);
    }

    setEntityMetadata(entityType: string, entityId: string, refType: string, refId: string, metadata: any)
    {
        return this.put<void>(`${this.apiUrl}/${entityType}/${entityId}/metadata/for/${refType}/${refId}`, metadata);
    }

    removeEntityMetadata(entityType: string, entityId: string, refType: string, refId: string)
    {
        return this.delete<void>(`${this.apiUrl}/${entityType}/${entityId}/metadata/for/${refType}/${refId}`);
    }

    getEntitiesWithMetadataFrom<T>(entityType: string, refEntityType: string, refEntityId: string): Observable<T[]>
    {
        return this.get<T[]>(`${this.apiUrl}/${refEntityType}/${refEntityId}/from/metadata/${entityType}`);
    }

    getRelationshipProperties<T>(relationshipType: string, sourceEntityType: string, sourceEntityId: string, destEntityType: string, destEntityId: string): Observable<T>
    {
        return this.get<T>(`${this.apiUrl}/${sourceEntityType}/${sourceEntityId}/relationship/${relationshipType}/${destEntityType}/${destEntityId}`);
    }

    setRelationshipProperties(relationshipType: string, sourceEntityType: string, sourceEntityId: string, destEntityType: string, destEntityId: string, properties: any): Observable<void>
    {
        return this.put<void>(`${this.apiUrl}/${sourceEntityType}/${sourceEntityId}/relationship/${relationshipType}/${destEntityType}/${destEntityId}`, properties);
    }

    getEntityDependencies<T>(entityType: string, entityId: string, requiredEntityType: string): Observable<T[]>
    {
        return this.get<T[]>(`${this.apiUrl}/${entityType}/${entityId}/depends/on/${requiredEntityType}`);
    }

    addEntityDependency(entityType: string, entityId: string, requiredEntityType: string, requiredEntityId: string): Observable<void>
    {
        return this.post<void>(`${this.apiUrl}/${entityType}/${entityId}/depends/on/${requiredEntityType}/${requiredEntityId}`, {});
    }

    removeEntityDependency(entityType: string, entityId: string, requiredEntityType: string, requiredEntityId: string): Observable<void>
    {
        return this.delete<void>(`${this.apiUrl}/${entityType}/${entityId}/depends/on/${requiredEntityType}/${requiredEntityId}`);
    }
}
