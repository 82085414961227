import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, catchError, combineLatest, debounceTime, exhaustMap, map, of, switchMap, tap, timer, withLatestFrom } from 'rxjs';
import { CoverageApiService } from '@involi/api-client';
import { DataOverlay } from '../data/data-overlay';
import { CoverageRenderer } from './coverage.renderer';
import { Bounds } from '../map/map';
import { MapManagerService } from '../map/services/map-manager.service';

@Injectable()
export class CoverageService
{
    private renderer: CoverageRenderer;
    private dataOverlay?: DataOverlay;
    private altitude$ = new BehaviorSubject<number | undefined>(undefined);
    private enabled$ = new BehaviorSubject<boolean>(false);

    constructor(coverageApi: CoverageApiService,
                mapManager: MapManagerService)
    {
        this.renderer = new CoverageRenderer('coverage');

        this.enabled$.pipe(
            tap((enabled: boolean) => {
                if(!enabled && this.dataOverlay)
                    this.renderer.clear(this.dataOverlay);
            }),
            switchMap((enabled: boolean) => {
                return combineLatest(
                    [this.altitude$, mapManager.getMainBoundsUpdates()]
                ).pipe(
                    map(([altitude, bounds]): [boolean, number | undefined, Bounds] => [enabled, altitude, bounds])
                );
            }),
            debounceTime(1000),
            switchMap(([enabled, altitude, bounds]) =>
                enabled
                ? timer(0, 5000).pipe(map((): [number | undefined, Bounds] => [altitude, bounds]))
                : EMPTY
            ),
            exhaustMap(([altitude, bounds]) => {
                return coverageApi.getCoverageCellIds({
                    southLatitude: bounds.sw.latitude,
                    northLatitude: bounds.ne.latitude,
                    westLongitude: bounds.sw.longitude,
                    eastLongitude: bounds.ne.longitude
                }, altitude);
            }),
            catchError((error: unknown) => {
                console.log(error);
                return of([]);
            })
        ).subscribe((cellIds: string[]) => {
            if(this.dataOverlay && this.enabled$.getValue())
                this.renderer.render(this.dataOverlay, cellIds);
        });
    }

    init(dataOverlay: DataOverlay)
    {
        this.dataOverlay = dataOverlay;
    }

    isEnabled(): boolean
    {
        return this.enabled$.getValue();
    }

    enable(enabled: boolean)
    {
        this.enabled$.next(enabled);
    }

    getCurrentAltitude(): number | undefined
    {
        return this.altitude$.getValue();
    }

    setAltitude(altitude?: number)
    {
        this.altitude$.next(altitude);
    }
}