import { IsEnum, IsJSON, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { Entity } from '../entity/entity.js';
import { AffixInstanceState } from './affix-instance-state.js';

export class DataRecordingEntity extends Entity
{
    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    affixConfiguration!: string;

    @IsJSON()
    @IsOptional()
    recordingParameters?: string;

    @IsString()
    @IsOptional()
    s3_bucket?: string;

    @IsString()
    @IsOptional()
    destinationDirectory?: string;

    @IsNumber()
    @IsOptional()
    startTimestamp?: number;

    @IsNumber()
    @IsOptional()
    endTimestamp?: number;

    @IsNumber()
    @IsOptional()
    scheduledStartTimestamp?: number;

    @IsNumber()
    @IsOptional()
    scheduledEndTimestamp?: number;

    @IsEnum(AffixInstanceState)
    state!: AffixInstanceState;

    @IsJSON()
    @IsOptional()
    metrics?: string;
}