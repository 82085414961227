import { PolygonLayer } from '@deck.gl/layers';
import { PickingInfo } from '@deck.gl/core';
import { ZoneIterable } from './zone-iterator';
import { ZoneRenderData } from './zone-render-data';
import { AlertEntry } from './alert.entry';
import { DataOverlay, LayerLevel } from '../data/data-overlay';
import { DataView } from '../data/data-view';
import { Renderer } from '../data/renderer';
import { SelectionController } from '../data/selection-controller';

export class AlertRenderer implements Renderer<AlertEntry>
{
    private selectionController?: SelectionController;

    constructor(private id: string)
    {

    }

    attachSelectionController(selectionController?: SelectionController)
    {
        this.selectionController = selectionController;
    }

    render(dataOverlay: DataOverlay, data: DataView<AlertEntry>)
    {
        const zones: Iterable<ZoneRenderData> = new ZoneIterable(data.getEntries());
        const zoneData = [...zones]; // needed for info.object to be populated in picking info, otherwise if it is an iterable only index is populated
        const layer = new PolygonLayer<ZoneRenderData>({
            id: this.id,
            data: zoneData,
            stroked: true,
            filled: true,
            positionFormat: 'XY',
            lineWidthMinPixels: 1,
            lineWidthUnits: 'pixels',
            getLineWidth: 2,
            getPolygon: (zone: ZoneRenderData) => zone.polygon,
            getFillColor: (zone: ZoneRenderData) => zone.fillColor,
            getLineColor: (zone: ZoneRenderData) => zone.lineColor,
            onClick: ((info: PickingInfo) => {
                const item: ZoneRenderData = info.object;
                this.selectionController!.selectItem(item.alert.id);
                return true;
            }),
            pickable: !!this.selectionController
        });
        dataOverlay.setLayer(layer, LayerLevel.Background);
    }

    clear(dataOverlay: DataOverlay)
    {
        dataOverlay.removeLayer(this.id);
    }
}
