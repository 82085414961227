import { unit, createUnit } from 'mathjs';

createUnit('knot', '1.852 km/h', { aliases: ['knots', 'kts'] });

export function mToFt(meters: number): number
{
    return unit(meters, 'm').toNumber('ft');
}

export function ftToM(feet: number): number
{
    return unit(feet, 'ft').toNumber('m');
}

export function mpsToFpm(mps: number): number
{
    return unit(mps, 'm/s').toNumber('ft/min');
}

export function mpsToKts(mps: number): number
{
    return unit(mps, 'm/s').toNumber('knot');
}