import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PermissionsService } from './permissions.service';
import { Observable, map, tap } from 'rxjs';

export function canActivatePermissionFlag(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree>
{
    const permissionsService = inject(PermissionsService);
    const router = inject(Router);

    return permissionsService.hasPermission(route.data.permissionFlag).pipe(
        map((hasPermission: boolean) => hasPermission ? true : router.parseUrl(''))
    );
}