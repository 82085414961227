import { NgZone } from '@angular/core';
import { LayerDataSource, PickingInfo } from '@deck.gl/core';
import { IconLayer, TextLayer, TextLayerProps } from '@deck.gl/layers';
import { DataView } from '../data/data-view';
import { DataOverlay, LayerLevel } from '../data/data-overlay';
import { Renderer } from '../data/renderer';
import { SelectionController } from '../data/selection-controller';
import { MapLabelStyle } from '../map/features/map-features';
import { RoundedTextBackgroundLayer } from '../map/google-maps/rounded-text-background-layer/rounded-text-background.layer';
import { ZIndexedMultiIconLayer } from '../map/google-maps/z-indexed-multi-icon-layer/z-indexed-multi-icon.layer';
import { ReceiverEntry } from './receiver.entry';

export class ReceiverRenderer implements Renderer<ReceiverEntry>
{
    private selectionController?: SelectionController;
    private iconLayerId: string;
    private labelLayerId: string;

    private lastDataOverlay?: DataOverlay;
    private lastData?: DataView<ReceiverEntry>;
    private areLabelsRendered: boolean = false;
    private hoveredObjectId?: string;
    private selectedObjectIds: string[] = [];

    constructor(id: string,
                private labelStyle: MapLabelStyle,
                private selectedLabelStyle: MapLabelStyle,
                private zone: NgZone)
    {
        this.iconLayerId = `${id}-icons`;
        this.labelLayerId = `${id}-labels`;
    }

    attachSelectionController(selectionController?: SelectionController)
    {
        this.selectionController = selectionController;
        this.selectionController?.selectedIds$.subscribe((ids: Set<string>) => this.selectedObjectIds = [...ids]);
    }

    isDisplayable(entry: ReceiverEntry): boolean
    {
        return !!entry.receiver.item.latitude && !!entry.receiver.item.longitude;
    }

    render(dataOverlay: DataOverlay, data: DataView<ReceiverEntry>)
    {
        this.lastDataOverlay = dataOverlay;
        this.lastData = data;
        const displayableEntries = data.findEntries(this.isDisplayable);

        const iconSizeScale = Math.max(0.1, ((dataOverlay.getMap().getZoom() ?? 8) - 3) * 0.25);
        const iconLayer = new IconLayer({
            id: this.iconLayerId,
            data: [...displayableEntries],
            getIcon: (d: ReceiverEntry) => d.receiver.icon.load,
            getSize: (d: ReceiverEntry) => d.receiver.icon.size*iconSizeScale,
            getPosition: (d: ReceiverEntry) => [d.receiver.item.longitude!, d.receiver.item.latitude!],
            onHover: this.onHover.bind(this, dataOverlay.getMap()),
            alphaCutoff: 0,
            pickable: !!this.selectionController,
            onClick: ((info: PickingInfo) => {
                const item: ReceiverEntry = info.object;
                this.zone.run(() => this.selectionController!.selectItem(item.receiver.item.primary_id));
                return true;
            })
        });

        this.areLabelsRendered = this.shouldRenderLabel(dataOverlay);
        let textLayerData: LayerDataSource<ReceiverEntry> = [];
        if(this.areLabelsRendered)
            textLayerData = displayableEntries;
        else
        {
            const arrayData: ReceiverEntry[] = [];
            if(this.hoveredObjectId)
            {
                const hoveredEntry = data.tryGet(this.hoveredObjectId);
                if(hoveredEntry && this.isDisplayable(hoveredEntry))
                    arrayData.push(hoveredEntry);
            }

            for(let selectedId of this.selectedObjectIds)
            {
                const selectedEntry = data.tryGet(selectedId);
                if(selectedEntry && this.isDisplayable(selectedEntry))
                    arrayData.push(selectedEntry);
            }
            textLayerData = arrayData;
        }

        const textLayerOptions: TextLayerProps<ReceiverEntry> = {
            id: this.labelLayerId,
            data: textLayerData,
            fontFamily: 'Inter',
            getText: (d: ReceiverEntry) => d.receiver.item.label ?? '',
            getPosition: (d: ReceiverEntry, { index }) => [d.receiver.item.longitude ?? 0, d.receiver.item.latitude ?? 0, data.size() - index],
            getSize: (d: ReceiverEntry) => d.selected ? this.selectedLabelStyle.fontSize! : this.labelStyle.fontSize!,
            getTextAnchor: 'middle',
            getAlignmentBaseline: 'center',
            getPixelOffset: (d: ReceiverEntry) => [
                d.receiver.icon.labelOrigin.x*iconSizeScale,
                d.receiver.icon.labelOrigin.y*iconSizeScale
            ],
            background: true,
            backgroundPadding: [10, 3],
            getColor: (d: ReceiverEntry) => d.selected ? this.selectedLabelStyle.textColor! : this.labelStyle.textColor!,
            getBorderColor: (d: ReceiverEntry) => d.selected ? this.selectedLabelStyle.borderColor! : this.labelStyle.borderColor!,
            getBorderWidth: (d: ReceiverEntry) => d.selected ? this.selectedLabelStyle.borderWidth! : this.labelStyle.borderWidth!,
        };
        (<any>textLayerOptions)._subLayerProps = {
            background: { type: RoundedTextBackgroundLayer },
            characters: { type: ZIndexedMultiIconLayer }
        };
        const labelLayer = new TextLayer(textLayerOptions);

        dataOverlay.setLayers([iconLayer, labelLayer], LayerLevel.Receivers);
    }

    private shouldRenderLabel(dataOverlay: DataOverlay)
    {
        return (dataOverlay.getMap().getZoom() ?? 0) >= 10;
    }

    rerender()
    {
        if(this.lastDataOverlay && this.lastData)
            this.render(this.lastDataOverlay, this.lastData);
    }

    clear(dataOverlay: DataOverlay)
    {
        dataOverlay.removeLayers([this.iconLayerId, this.labelLayerId]);
    }

    onHover(map: google.maps.Map, info: PickingInfo)
    {
        map.setOptions({ draggableCursor: info.object ? 'pointer' : 'grab' });

        if(this.areLabelsRendered) return;

        this.hoveredObjectId = info.object?.id;
        this.rerender();
    }
}