import { Action, createFeatureSelector, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { defaultMapCenter, defaultMapTypeId, defaultMapZoom, LatLng, MapChart } from '../map';
import {
    setMapCenter, setMapZoom, setSelectedRecordIds, addSelectedRecordId, removeSelectedRecordId,
    setMapTypeId, enableReceiversLayer, setChartVisible, setAllChartVisible, setCharts,
    addCharts, removeChart, enableAlertsLayer
} from './map.actions';

export const featureKey = 'map';

export interface State
{
    mapCenter: LatLng;
    mapZoom: number;
    mapTypeId: string;
    mapCharts: { [name: string]: MapChart };
    mapSelectedRecordIds: string[];
    receiversLayerEnabled: boolean;
    alertsLayerEnabled: boolean;
}

export const initialState: State = {
    mapCenter: defaultMapCenter,
    mapZoom: defaultMapZoom,
    mapTypeId : defaultMapTypeId,
    mapCharts: {},
    mapSelectedRecordIds: [],
    receiversLayerEnabled: false,
    alertsLayerEnabled: false
}

const _reducer = createReducer(
    initialState,
    immerOn(setChartVisible, (state: State, { chartName, visible }) => { state.mapCharts[chartName].visible = visible }),
    immerOn(setAllChartVisible, (state: State, { visible }) => {
        Object.values(state.mapCharts).forEach((chart: MapChart) => chart.visible = visible);
    }),
    immerOn(setCharts, (state: State, { charts }) => {
        state.mapCharts = {};
        charts.forEach((chart: MapChart) => state.mapCharts[chart.name] = chart);
    }),
    immerOn(addCharts, (state: State, { charts }) => {
        charts.forEach((chart: MapChart) => state.mapCharts[chart.name] = chart);
    }),
    immerOn(removeChart, (state, { chartName }) => {
        state.mapCharts = Object.fromEntries(
            Object.keys(state.mapCharts).filter((name: string) => name != chartName)
            .map((name: string) => [name, state.mapCharts[name]])
        );
    }),
    immerOn(setMapCenter, (state, { center }) => { state.mapCenter = center }),
    immerOn(setMapZoom, (state, { zoom }) => { state.mapZoom = zoom }),
    immerOn(setMapTypeId, (state, { typeId }) => { state.mapTypeId = typeId }),
    immerOn(setSelectedRecordIds, (state, { selectedRecordIds }) => { state.mapSelectedRecordIds = selectedRecordIds }),
    immerOn(addSelectedRecordId, (state, { selectedRecordId }) => { state.mapSelectedRecordIds = [...new Set([...state.mapSelectedRecordIds, selectedRecordId])] }),
    immerOn(removeSelectedRecordId, (state, { deselectedRecordId }) => { state.mapSelectedRecordIds = state.mapSelectedRecordIds.filter((id: string) => { return id != deselectedRecordId; }) }),
    immerOn(enableReceiversLayer, (state, { enabled }) => { state.receiversLayerEnabled = enabled }),
    immerOn(enableAlertsLayer, (state, { enabled }) => { state.alertsLayerEnabled = enabled })
);

export function reducer(state: State, action: Action)
{
    return _reducer(state, action);
}

export const selectFeature = createFeatureSelector<State>(featureKey);
export const mapCharts = createSelector(selectFeature, (state: State) => Object.values(state.mapCharts));
export const selectMapCenter = createSelector(selectFeature, (state: State) => state.mapCenter);
export const selectMapZoom = createSelector(selectFeature, (state: State) => state.mapZoom);
export const selectMapTypeId = createSelector(selectFeature, (state: State) => state.mapTypeId);
export const selectMapAttributes = createSelector(
    selectMapZoom,
    selectMapCenter,
    selectMapTypeId,
    (zoom: number, center: LatLng, typeId: string) => ({ zoom, center, typeId})
);
export const selectMapSelectedRecordIds = createSelector(selectFeature, (state: State) => state.mapSelectedRecordIds);
export const selectReceiversLayerEnabled = createSelector(selectFeature, (state: State) => state.receiversLayerEnabled);
export const selectAlertsLayerEnabled = createSelector(selectFeature, (state: State) => state.alertsLayerEnabled);