import { IsLatitude, IsLongitude, IsNumber, IsOptional, IsString, Min } from "class-validator";
import { SettingsEntity } from "../entity/settings.entity.js";
import { createSettings } from "../settings/settings.js";

export const InvoliLiveSettingsKey = 'involi_live';

export class InvoliLiveSettingsEntity extends SettingsEntity
{
    @IsNumber()
    @Min(0)
    @IsOptional()
    zoom_level?: number;

    @IsNumber()
    @IsLatitude()
    @IsOptional()
    center_latitude?: number;

    @IsNumber()
    @IsLongitude()
    @IsOptional()
    center_longitude?: number;

    @IsString()
    @IsOptional()
    map_type?: string;
}

createSettings(InvoliLiveSettingsKey, InvoliLiveSettingsEntity);