import { PredefinedIcon, PredefinedIconVariation } from '../icon';

export enum LabelSegmentType
{
    Title,
    Altitude,
    Speed
}

export type TitleSegment = [type: LabelSegmentType.Title, title: string];
export type AltitudeSegment = [type: LabelSegmentType.Altitude, altitude: number, verticalSpeed: number];
export type SpeedSegment = [type: LabelSegmentType.Speed, speed: number];
export type LabelSegment =
    | TitleSegment
    | AltitudeSegment
    | SpeedSegment;

export type GeoObject = [
    id: string,
    icon: PredefinedIcon,
    iconVariation: PredefinedIconVariation | undefined,
    latitude: number,
    longitude: number,
    heading: number | undefined,
    label: LabelSegment[]
];

export interface GeoObjectGroup
{
    sourceId: string;
    geoObjects: GeoObject[];
};

export enum GeoObjectIndex
{
    Id,
    Icon,
    IconVariation,
    Latitude,
    Longitude,
    Heading,
    Label
}