export interface LatLng
{
    latitude: number;
    longitude: number;
}

export interface Bounds
{
    sw: LatLng;
    ne: LatLng;
}

export interface GeoJsonData
{
    name: string;
    source: string;
}

export enum MapChartType
{
    FOCA,
    Generic
}

export interface MapChart
{
    name: string;
    visible: boolean;
    type: MapChartType;
}

export const defaultMapCenter: LatLng = {  latitude: 46.5, longitude: 6.5 };
export const defaultMapZoom: number = 8;
export const defaultMapTypeId: string = 'dark';