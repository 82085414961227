import { Injectable } from '@angular/core';
import { TrafficApiService } from '@involi/api-client';
import { Observable, Subject, shareReplay, startWith, switchMap, timer } from 'rxjs';
import { SourceInfo } from '@involi/api-shared';

@Injectable()
export class TrafficSourcesService
{
    private sources$ = new Observable<SourceInfo[]>();
    private refresh$ = new Subject<void>();

    constructor(private trafficApi: TrafficApiService)
    {
        this.sources$ = this.refresh$.pipe(
            startWith(null),
            switchMap(() => timer(0, 10000)),
            switchMap(() => this.trafficApi.getSourcesWithMetadata()),
            shareReplay(1)
        );
    }

    sources(): Observable<SourceInfo[]>
    {
        return this.sources$;
    }

    refresh()
    {
       this.refresh$.next();
    }
}