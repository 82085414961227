import { IsArray, IsEnum, IsNumber, IsOptional, ValidateNested } from 'class-validator';
import { TrafficBox } from './traffic-box.dto.js';
import { RecordFormat } from './record-format.js';

export class GetTrafficFromCollectionsDto
{
    @IsArray()
    collectionIds!: string[];

    @IsOptional()
    @ValidateNested()
    boundingBox?: TrafficBox;

    @IsOptional()
    @IsNumber()
    upperAltitude?: number;

    @IsOptional()
    @IsNumber()
    lowerAltitude?: number;

    @IsOptional()
    @IsEnum(RecordFormat)
    format?: RecordFormat;
}