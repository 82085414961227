import { NgModule } from '@angular/core';
import { ReceiverApiModule } from '@involi/api-client';
import { ReceiversService } from './receivers.service';
import { DataModule } from '../data';
import { ReceiverFilter } from './receiver.filter';

@NgModule({
    imports: [ReceiverApiModule],
    providers: [
        ReceiversService,
        ReceiverFilter
    ]
})
export class ReceiversModule {}