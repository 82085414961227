import { IsLatitude, IsLongitude, IsNotEmpty, IsNumber, IsOptional, IsString, Min, ValidateNested } from 'class-validator';
import { SettingsEntity } from '../entity';
import { Type } from 'class-transformer';

export class AddSettingsForEntityDto
{
    @IsString()
    @IsNotEmpty()
    configId!: string;
}

export class GetSettingsDto<E extends SettingsEntity = SettingsEntity>
{
    @IsOptional()
    settings?: E;
}

export class EditableLiveSettings
{
    @IsNumber()
    @Min(0)
    @IsOptional()
    zoom_level?: number;

    @IsNumber()
    @IsLatitude()
    @IsOptional()
    center_latitude?: number;

    @IsNumber()
    @IsLongitude()
    @IsOptional()
    center_longitude?: number;

    @IsString()
    @IsOptional()
    map_type?: string;
}

export class UpdateLiveSettingsDto
{
    @Type(() => EditableLiveSettings)
    @ValidateNested()
    settings!: EditableLiveSettings;
}