import { Position } from '@deck.gl/core';
import { TrajectoryLocation } from '@involi/api-client';

export class TrajectorySubEntry
{
    item!: TrajectoryLocation[];
    path!: Position[];
}

export interface TrajectoryEntry
{
    trajectory: TrajectorySubEntry;
}