import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import { GeojsonInfoLayerComponent } from './google-maps/geojson-info-layer/geojson-info-layer.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { StoreModule } from '@ngrx/store';
import * as fromMap from './store/map.reducer';
import { TrackersModule } from '../tracker';
import { LocationSearchComponent } from './google-maps/location-search/location-search.component';
import { UiModule } from '../ui';
import { TrafficApiModule, TrajectoryApiModule } from '@involi/api-client';
import { PortalModule } from '@angular/cdk/portal';
import { InvoliGoogleMapComponent } from './google-maps/involi-google-map/involi-google-map.component';
import { DataModule } from '../data/data.module';
import { MapIconService } from './services/map-icon.service';
import { MapManagerService } from './services/map-manager.service';
import { ManageMapDirective } from './services/manage-map.directive';
import { PermissionsModule } from '../permissions';
import { ReceiversModule } from '../receiver';

@NgModule({
    declarations: [
        GeojsonInfoLayerComponent,
        LocationSearchComponent,
        InvoliGoogleMapComponent,
        ManageMapDirective
    ],
    imports: [
        OverlayModule,
        CommonModule,
        GoogleMapsModule,
        PermissionsModule,
        TrackersModule,
        UiModule,
        TrafficApiModule,
        TrajectoryApiModule,
        StoreModule.forFeature(fromMap.featureKey, fromMap.reducer),
        PortalModule,
        DataModule,
        ReceiversModule
    ],
    exports: [
        InvoliGoogleMapComponent,
        GeojsonInfoLayerComponent,
        ManageMapDirective
    ],
    providers: [
        MapIconService,
        MapManagerService
    ]
})
export class MapModule
{
    static title = 'map';
}
