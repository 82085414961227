import { IsOptional, IsString } from "class-validator";
import { SettingsEntity } from "../entity/settings.entity.js";
import { createSettings } from "../settings/settings.js";

export const RecordingS3SettingsKey = 'recording_s3';

export class S3RecordingSettingsEntity extends SettingsEntity
{
    @IsOptional()
    @IsString()
    bucket?: string;
}

createSettings(RecordingS3SettingsKey, S3RecordingSettingsEntity);