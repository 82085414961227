import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig, API_CONFIG } from '../api';
import { Observable } from 'rxjs';
import { SetFlagDto } from '@involi/api-shared';
import { ApiClient } from '../core/api-client';
import { ApiStatusService } from '../core';

@Injectable({
    providedIn: 'root'
})
export class NodeApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                http: HttpClient,
                apiStatus: ApiStatusService)
    {
        super(apiStatus, http, 'NodeJS');
        this.apiUrl = `${config.involiApiUrl}/admin/node`;
    }

    enableTraceGc(): Observable<void>
    {
        return this.get<void>(`${this.apiUrl}/trace-gc/enable`);
    }

    disableTraceGc(): Observable<void>
    {
        return this.get<void>(`${this.apiUrl}/trace-gc/disable`);
    }

    enableInspector(): Observable<void>
    {
        return this.get<void>(`${this.apiUrl}/inspector/enable`);
    }

    disableInspector(): Observable<void>
    {
        return this.get<void>(`${this.apiUrl}/inspector/disable`);
    }

    setFlag(flag: string): Observable<void>
    {
        const request: SetFlagDto = { flag };
        return this.post<void>(`${this.apiUrl}/flag/set`, request);
    }
}