import { Injectable } from '@angular/core';
import { EMPTY, Subject, catchError, exhaustMap, switchMap, tap, timer } from 'rxjs';
import { TrajectoryApiService, TrajectoryLocations } from '@involi/api-client';
import { DataOverlay } from '../data/data-overlay';
import { DataController } from '../data/data-controller';
import { TrajectoryEntry, TrajectorySubEntry } from './trajectory.entry';
import { TrajectoryRenderer } from './trajectory.renderer';
import { DataCollection } from '../data/data-collection';
import { DataProvider } from '../data/data-provider';

@Injectable()
export class TrajectoryService
{
    private collection: DataCollection;
    private dataController: DataController<TrajectoryEntry, TrajectorySubEntry>;
    private renderer: TrajectoryRenderer;
    private refresh$ = new Subject<void>();
    private aircaftId = new Subject<string | undefined>();

    constructor(private trajectoryApi: TrajectoryApiService,
                private dataProvider: DataProvider)
    {
        this.collection = new DataCollection();
        this.dataController = new DataController({
            collection: this.collection,
            key: 'trajectory',
            defaultSubEntry: () => new TrajectorySubEntry(),
            entryDerivator: (entry: TrajectoryEntry) => {
                entry.trajectory.path = entry.trajectory.item.map(p => [p.longitude, p.latitude]);
            }
        });

        this.renderer = new TrajectoryRenderer('trajectory', {
            lineWidth: 2,
            lineColor: [85, 83, 218, 255]
        });
    }

    init(dataOverlay: DataOverlay)
    {
        this.dataController.attachOverlay(dataOverlay, this.renderer);

        const all$ = this.aircaftId.pipe(
            switchMap((id: string | undefined) => {
                this.dataController.getCollectionLayer().releaseAll();
                if(!id)
                    return EMPTY;
                return this.dataProvider.getPollingRate().pipe(
                    switchMap((pollingRate: number) => timer(0, pollingRate)),
                    exhaustMap(() => this.trajectoryApi.getTrajectory(id).pipe(catchError(() => EMPTY))),
                    tap((data: TrajectoryLocations) => {
                        this.dataController.getCollectionLayer().setEntryProperty('item', id, data.locations);
                    })
                );
            })
        );

        this.dataController.attachSource(all$);
    }

    refresh()
    {
        this.refresh$.next();
    }

    getDataController(): DataController<TrajectoryEntry, TrajectorySubEntry>
    {
        return this.dataController;
    }

    setPolledAircraftId(id: string | undefined)
    {
        this.aircaftId.next(id);
    }
}