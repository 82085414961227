import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, Subject, catchError, exhaustMap, filter, map, merge, of, share, shareReplay, startWith, switchMap, tap, timer, withLatestFrom } from 'rxjs';
import { ReceiverDataDto, TrafficDataDto } from '@involi/api-shared';
import { AuthService, DataApiService } from '@involi/api-client';
import { MapManagerService } from '../map/services/map-manager.service';
import { DataCollection, trackSubscription } from './data-collection';
import { SelectionController } from './selection-controller';
import { selectReceiversLayerEnabled } from '../map/store/map.reducer';
import { DataPreferencesService } from './data-preferences.service';

interface ProcessingTracker
{
    handlerLeft: number;
}

@Injectable()
export class DataProvider
{
    private collection: DataCollection;
    private selectionController: SelectionController;
    private trafficDataStream$: Observable<[TrafficDataDto, ProcessingTracker]>;
    private receiverDataStream$: Observable<[ReceiverDataDto, ProcessingTracker]>;
    private refresh$ = new Subject<void>();
    private pollingRate$: Observable<number>;
    private trafficDataStreamSubscriptions: number = 0;
    private receiverDataStreamSubscriptions: number = 0;
    private includeSujectTrackers: boolean = false;

    constructor(private dataApi: DataApiService,
                private mapManagerService: MapManagerService,
                private store: Store,
                private dataPreferencesService: DataPreferencesService,
                private authService: AuthService)
    {
        this.collection = new DataCollection();
        this.selectionController = new SelectionController(this.collection);

        this.pollingRate$ = this.mapManagerService.getZoomUpdates().pipe(
            map((zoom: number) => Math.max(30000/Math.max(zoom, 4) - 2500, 500)),
            startWith(1000),
            shareReplay(1)
        );

        const pollTrigger$ = merge(
            this.pollingRate$.pipe(switchMap((pollingRate: number) => timer(0, pollingRate))),
            this.refresh$,
            this.selectionController.selectedIds$.pipe(filter((ids: Set<string>) => !!ids.size))
        ).pipe(
            withLatestFrom(this.authService.isAuthenticated()),
            filter(([_ ,isAuthenticated]) => isAuthenticated)
        );

        this.trafficDataStream$ = pollTrigger$.pipe(
            withLatestFrom(
                this.selectionController.selectedIds$,
                this.store.select(selectReceiversLayerEnabled)
            ),
            exhaustMap(([_, selectedIds, receiversLayerEnabled]): Observable<TrafficDataDto> => {
                const bounds = this.mapManagerService.getMainBounds();
                if(receiversLayerEnabled)
                    return of({});
                return this.dataApi.getTrafficData({
                    // Move the connection between the map manager service and this request option outside DataProvider
                    boundingBox: {
                        southLatitude: bounds.sw.latitude, westLongitude: bounds.sw.longitude,
                        northLatitude: bounds.ne.latitude, eastLongitude: bounds.ne.longitude,
                        isNotStrict: true
                    },
                    selectedTrafficSourceIds: this.dataPreferencesService.getSelectedSources(),
                    upperAltitude: this.dataPreferencesService.getUpperAltitude(),
                    lowerAltitude: this.dataPreferencesService.getLowerAltitude(),
                    additionalAircraftIds: receiversLayerEnabled ? [] : [...selectedIds],
                    includeSubjectTrackers: this.includeSujectTrackers && !receiversLayerEnabled,
                    includeTraffic: !receiversLayerEnabled
                }).pipe(
                    catchError((error: any) => EMPTY)
                );
            }),
            map((data: TrafficDataDto): [TrafficDataDto, ProcessingTracker] => {
                this.collection.bulkChangeStart();
                return [data, { handlerLeft: this.trafficDataStreamSubscriptions }];
            }),
            share()
        );

        this.receiverDataStream$ = pollTrigger$.pipe(
            withLatestFrom(this.store.select(selectReceiversLayerEnabled)),
            exhaustMap(([_, receiversLayerEnabled]): Observable<ReceiverDataDto> => {
                if(!receiversLayerEnabled)
                    return of({});
                return this.dataApi.getReceiverData().pipe(
                    catchError((error: any) => EMPTY)
                );
            }),
            map((data: ReceiverDataDto): [ReceiverDataDto, ProcessingTracker] => {
                this.collection.bulkChangeStart();
                return [data, { handlerLeft: this.receiverDataStreamSubscriptions }];
            }),
            share()
        );

        this.dataPreferencesService.onChange().subscribe(() => this.refresh());
    }

    getDataCollection(): DataCollection
    {
        return this.collection;
    }

    getSelectionController(): SelectionController
    {
        return this.selectionController;
    }

    getPollingRate(): Observable<number>
    {
        return this.pollingRate$;
    }

    requestSubjectTrackers(doRequest: boolean)
    {
        this.includeSujectTrackers = doRequest;
    }

    watchTraffic(fn: (data: TrafficDataDto) => void): Observable<void>
    {
        return this.trafficDataStream$.pipe(
            trackSubscription((sub: boolean) => this.trafficDataStreamSubscriptions += sub ? 1 : -1),
            tap(([data, _]) => fn(data)),
            map(([_, tracker]) => {
                tracker.handlerLeft--;
                if(!tracker.handlerLeft)
                    this.collection.bulkChangeEnd();
            })
        );
    }

    watchReceivers(fn: (data: ReceiverDataDto) => void): Observable<void>
    {
        return this.receiverDataStream$.pipe(
            trackSubscription((sub: boolean) => this.receiverDataStreamSubscriptions += sub ? 1 : -1),
            tap(([data, _]) => fn(data)),
            map(([_, tracker]) => {
                tracker.handlerLeft--;
                if(!tracker.handlerLeft)
                    this.collection.bulkChangeEnd();
            })
        );
    }

    refresh()
    {
        this.refresh$.next();
    }
}