import { BehaviorSubject, Observable } from 'rxjs';
import { DataCollection, DataCollectionLayer } from './data-collection';

export interface Selectable
{
    selected: boolean;
}

export enum SelectionPolicy
{
    Single,
    Multi
}

export class SelectionController
{
    private selectedIds = new Set<string>();
    private selectedIdsSubject = new BehaviorSubject<Set<string>>(this.selectedIds);
    selectedIds$: Observable<Set<string>> = this.selectedIdsSubject.asObservable();
    private selectionLayer: DataCollectionLayer<Selectable, boolean>;

    constructor(private collection: DataCollection, private selectionPolicy: SelectionPolicy = SelectionPolicy.Single)
    {
        this.selectionLayer = new DataCollectionLayer(collection, 'selected', () => false);
    }

    selectItem(id: string)
    {
        this.collection.bulkChangeStart();
        if(this.selectionPolicy == SelectionPolicy.Single)
            this.doDeselectAll();
        this.selectedIds.add(id);
        this.patchSelected(id, true);
        this.collection.bulkChangeEnd();
        this.selectedIdsSubject.next(this.selectedIds);
    }

    deselectItem(id: string)
    {
        if(this.selectedIds.delete(id))
            this.selectedIdsSubject.next(this.selectedIds);
        this.patchSelected(id, false);
    }

    deselectAll()
    {
        if(this.selectedIds.size)
        {
            this.collection.bulkChangeStart();
            this.doDeselectAll();
            this.collection.bulkChangeEnd();
            this.selectedIdsSubject.next(this.selectedIds);
        }
    }

    private doDeselectAll()
    {
        for(let id of this.selectedIds)
            this.patchSelected(id, false);
        this.selectedIds.clear();
    }

    private patchSelected(id: string, selected: boolean)
    {
        this.selectionLayer.getAndPatchEntry(id, { selected });
        if(!selected)
            this.selectionLayer.release(id);
    }
}