import { _TextBackgroundLayer } from '@deck.gl/layers';
import fs from './rounded-text-background-fragment.glsl';
import vs from './rounded-text-background-vertex.glsl';

export class RoundedTextBackgroundLayer extends _TextBackgroundLayer
{
    static layerName = 'RoundedTextBackgroundLayer';

    getShaders()
    {
        return Object.assign({}, super.getShaders(), { fs, vs });
    }
}