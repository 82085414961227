import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class DisplayPreferencesService
{
    private sourceColors: Map<string, string> = new Map<string, string>();
    private change$ = new Subject<void>();

    onChange(): Observable<void>
    {
        return this.change$.asObservable();
    }

    setSourceColor(sourceId: string, hexColor: string)
    {
        this.sourceColors.set(sourceId, hexColor);
        this.change$.next();
    }

    getSourceColor(sourceId: string): string | undefined
    {
        return this.sourceColors.get(sourceId);
    }
}